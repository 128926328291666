
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertService, AuthenticationService, UnitService, CountriesService, LoaderService, UserService, forbiddenIfSplCharAtStartValidator,LanguageOptions, CommonMethodService } from '@75f/portal-ui-components';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { AnalyticsService } from 'src/app/shared/_services/analytics.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-account-details',
    templateUrl: './account-details.component.html',
    styleUrls: ['./account-details.component.scss']
})
export class AccountDetailsComponent implements OnInit {
    accountForm: FormGroup;
    countryList = [];
    subscriptions = {};
    userName = '';
    namePattern = /^(?:[A-Za-z0-9\s]+)(?:[A-Za-z0-9_-\s]*)$/;
    partnerName;
    privacyStatement;
    termsOfService;

    // SMS Opt-in stuff
    previousPhoneNumber: string;
    languageOptions: { name: string; value: string; }[];

    get phoneNumberChanged(): boolean {
        return this.previousPhoneNumber != this.accountForm.controls.phone.value;
    }

    get hasPhoneNumber(): boolean {
        return this.accountForm.controls.phone.value;
    }

    get optInValid(): boolean {
        return !this.phoneNumberChanged || !this.hasPhoneNumber || (this.phoneNumberChanged && this.smsOptIn);
    }

    get smsOptIn(): boolean {
        return this.accountForm.controls.smsOptIn.value ?? false;
    }

    constructor(
        public unitService: UnitService,
        public fb: FormBuilder,
        public userService: UserService,
        public authService: AuthenticationService,
        public loaderService: LoaderService,
        public alertService: AlertService,
        public countriesService: CountriesService,
        private analyticsService: AnalyticsService,
        public commonService: CommonMethodService) {
        this.createAccountForm();
        this.setFormValues();
        this.getDetails();
    }

    ngOnInit() {
        this.countryList = this.countriesService.getCountries();
        this.countryList.unshift({ dialCode: '', displayName: 'N/A', flag: '', iso: '', name: '' });
        this.partnerName = environment.partnerName;
        this.privacyStatement = environment.privacyStatement;
        this.termsOfService = environment.termsOfService;
        this.languageOptions = LanguageOptions;
    }

    ngAfterViewInit() {
        let loadTime = Date.now() - this.analyticsService.getPageLoadStartTime();
        this.analyticsService.pageLoadTime(loadTime);
    }

    /**
     *  This method fetches logged in user details, if it fails then throws an error.
     */
    getDetails() {
        this.subscriptions['getusers'] = this.authService.fetchLoggedInUserDetails().subscribe({
            next: () => {
                this.setFormValues();
            },
            error: () => {
                this.setFormValues();
            }
        })
    }
    /**
     *  Here in this method it asks for user to fill the required feilds if not it will not proceed further.
     */
    createAccountForm() {
        this.accountForm = this.fb.group({
            "firstName": ["", [Validators.required, Validators.pattern(this.namePattern), forbiddenIfSplCharAtStartValidator]],
            "lastname": ["", [Validators.pattern(this.namePattern), forbiddenIfSplCharAtStartValidator]],
            "email": ["", [Validators.required, Validators.email]],
            "phone": [""],
            "smsOptIn": [""],
            "country": [""],
            "temperature": ["", [Validators.required]],
            "energyConsumption": ["", [Validators.required]],
            "airflowVolume": ["", [Validators.required]],
            "airPressure": ["", [Validators.required]],
            "waterPressure": ["", [Validators.required]],
            "waterFlow": ["", [Validators.required]],
            "language": ["", Validators.required]

        });
    }
    /**
     * In this method it gets details of logged in users and checks for current details and previous entered details.
     */
    setFormValues() {
        let user: any = this.authService.getLoggedInUserDetails();
        user['emailId'] = this.authService.getUser() ? this.authService.getUser().emailId : '';
        user['user_preference'] = this.authService.getLoggedInUserPreferences();
        this.userName = user.firstName + ' ' + user.lastName;
        this.accountForm.controls.firstName.setValue(user.firstName);
        this.accountForm.controls.lastname.setValue(user.lastName);
        this.accountForm.controls.email.setValue(user.emailId);
        this.accountForm.controls.phone.setValue(user.phoneNum);
        this.accountForm.controls.country.setValue(user.country);
        this.accountForm.controls.temperature.setValue(user.user_preference.temperatureUnit);
        this.accountForm.controls.energyConsumption.setValue(user.user_preference.energyConsumptionUnit);
        this.accountForm.controls.airflowVolume.setValue(user.user_preference.airflowVolumeUnit);
        this.accountForm.controls.airPressure.setValue(user.user_preference.airPressureUnit);
        this.accountForm.controls.waterPressure.setValue(user.user_preference.waterPressureUnit);
        this.accountForm.controls.waterFlow.setValue(user.user_preference.waterFlowUnit);
        this.accountForm.controls.language.setValue(user.user_preference.language);

        // Store previous phone number
        this.previousPhoneNumber = structuredClone(user.phoneNum);
    }
    /**
     *Here It submits the updated details if the user wants to change details, if the user entered correct details it updates else throws an error.
     */
    onSubmit() {
        if (this.accountForm.valid) {
            this.loaderService.active(true);
            let user = this.authService.getUser();
            let payload = {
                firstName: this.accountForm.controls.firstName.value,
                lastName: this.accountForm.controls.lastname.value,
                emailId: this.accountForm.controls.email.value,
                phoneNum: this.accountForm.controls.phone.value ?? '',
                phoneCountryCode: this.accountForm.controls.country.value ? this.countriesService.getByIsoName(this.accountForm.controls.country.value).dialCode : '',
                phoneSmsOptIn: this.smsOptIn,
                country: this.accountForm.controls.country.value ?? '',
                userPreferences: {
                    temperatureUnit: this.accountForm.controls.temperature.value,
                    energyConsumptionUnit: this.accountForm.controls.energyConsumption.value,
                    airflowVolumeUnit: this.accountForm.controls.airflowVolume.value,
                    airPressureUnit: this.accountForm.controls.airPressure.value,
                    waterPressureUnit: this.accountForm.controls.waterPressure.value,
                    waterFlowUnit: this.accountForm.controls.waterFlow.value,
                    language: this.accountForm.controls.language.value
                }
            }
            if (user.emailId != this.accountForm.controls.email.value) {
                payload['existingEmailId'] = user.emailId;
            }
            this.subscriptions['updateusers'] = this.userService.updateUserDetails(user.userId, payload).subscribe((res: any) => {

                if (res.success) {

                    // If the email Id was changed, then the user would need to be logged out. Since the token is now invalid
                    if (payload['existingEmailId']) {
                        this.alertService.success('Email updated successfully. <br/>Logging out in 5 secs');
                        setTimeout(() => {
                            this.authService.logout()
                        }, 5000);
                    } else {

                        this.authService.setLoggedInUserDetails({
                            firstName: this.accountForm.controls.firstName.value,
                            lastName: this.accountForm.controls.lastname.value,
                            phoneNum: this.accountForm.controls.phone.value,
                            country: this.accountForm.controls.country.value,
                            profilePic: null
                        })

                        this.authService.setLoggedInUserPreferences({
                            temperatureUnit: this.accountForm.controls.temperature.value,
                            energyConsumptionUnit: this.accountForm.controls.energyConsumption.value,
                            airflowVolumeUnit: this.accountForm.controls.airflowVolume.value,
                            airPressureUnit: this.accountForm.controls.airPressure.value,
                            waterPressureUnit: this.accountForm.controls.waterPressure.value,
                            waterFlowUnit: this.accountForm.controls.waterFlow.value,
                            language: this.accountForm.controls.language.value
                        })
                        this.accountForm.markAsPristine();
                        this.setFormValues();
                        this.accountForm.controls.smsOptIn.setValue(false);

                        this.loaderService.active(false);
                        this.alertService.success('Account details updated successfully');
 
                        if(this.accountForm.controls.language.value != 'en') {
                            document.body.classList.remove('notranslate');
                            this.commonService.applyPreselectedLanguage(this.accountForm.controls.language.value);
                        } else {
                            this.commonService.applyPreselectedLanguage(this.accountForm.controls.language.value);
                            document.body.classList.add('notranslate');
                        }
                    }
                } else {
                    this.loaderService.active(false);
                    this.alertService.error(res.msg);
                }
            }, err => {
                this.loaderService.active(false);
                this.alertService.error(err.error ? err.error.msg : 'Something went wrong, please try again');

            })
        }
    }
    /**
     * Here this method reset the change account-details.
     */
    cancelChanges() {
        this.setFormValues();
        this.accountForm.markAsPristine();
        this.accountForm.markAsUntouched();
    }
    /**
     * In this method it asks phone number to be in digits else it throws an error if any character is typed.
     */
    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {

            return false;
        }

        return true;

    }
    /**
     * It clears the data.
     */
    ngOnDestroy() {
        Object.keys(this.subscriptions).forEach((key) => {
            this.subscriptions[key].unsubscribe();
        })
    }
    /**
     * This method validate the phone number based on the country selected
     */
    phoneNumberValidator(form: FormGroup): void {
        this.accountForm.controls.phone.markAsTouched();

        const phoneNumberUtil = PhoneNumberUtil.getInstance();
        const phoneNumber: string = form.controls.phone.value?.trim() || '';
        const countryCode: string = form.controls.country.value?.trim() || '';

        if ((phoneNumber || '').trim().length !== 0) {
            try {
                if (countryCode == '') {
                    form.controls.country.setErrors({ 'requiredWithPhoneNum': true });
                    return;
                } else {
                    form.controls.country.setErrors(null);
                }
                const parsedPhoneNumber = phoneNumberUtil.parse('+' + this.countriesService.getByIsoName(form.controls.country.value).dialCode + phoneNumber);
                if (!phoneNumberUtil.isValidNumber(parsedPhoneNumber)) {
                    form.controls.phone.setErrors({ 'phoneNumberInvalid': true });
                    return;
                }
            } catch (error) {
                form.controls.phone.setErrors({ 'phoneNumberInvalid': true });
                return;
            }
        }
        form.controls.phone.setErrors(null);
    }

}
