import { PamWidgetService } from '@/shared/_services';
import { HelperServiceFacilisight } from '@/shared/_services/helper.service';
import { AuthenticationService, ConfigurationService, HelperService, UserService } from '@75f/portal-ui-components';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AnalyticsService } from 'src/app/shared/_services/analytics.service';

@Component({
  selector: 'fs-energy-dashboard-layout',
  templateUrl: './energy-dashboard-layout.component.html',
  styleUrls: ['./energy-dashboard-layout.component.scss']
})
export class EnergyDashboardLayoutComponent implements OnInit {
  urlSafe: SafeResourceUrl;
  baseUrl: string;
  siteRef: string;
  userPreferenceData: any = {
    userId: "",
    heatmapPreferences: {}
  };
  isVofm = false;
  isLoading = true;

  constructor(public sanitizer: DomSanitizer,
    private configService: ConfigurationService,  private helperService: HelperService,
    public userPref: UserService,
    @Inject('LOCALSTORAGE') public localStorage: any,
    public _activateRoute: ActivatedRoute,
    private pamWidgetService: PamWidgetService,
    private analyticsService: AnalyticsService,
    private authService: AuthenticationService,
    public helperServiceFs: HelperServiceFacilisight
  ) {
    this.baseUrl = this.configService.getConfig('pamUrl');
    window.addEventListener('DOMContentLoaded', (event) => {
      const ele1 = document.getElementById('loader');
      ele1.classList.add('bg');
    });
  }

  async ngOnInit() {
    const ele = document.querySelector('body');
    ele.style.overflowY = 'hidden';
    this.siteRef = this._activateRoute.snapshot.paramMap.get('id');
    const token = this.localStorage.getItem('bearer_token');
    const userData = JSON.parse(this.localStorage.getItem('user_data'));
    this.userPreferenceData.userId = this.authService.getUser() ? this.authService.getUser().userId : null;
    try {
      await this.getUserPreferencesData();
    } catch (e) { }
    const lastViewedDashboardId = this.userPreferenceData?.heatmapPreferences?.pamLastViewed || '';
    let url = '';
    const userPrefLangauge = this.helperServiceFs.getLanguagePrefToggle() ? (this.helperService.getUserPreferredLanguage() || 'en') : 'en';
    if (lastViewedDashboardId) {
      // tslint:disable-next-line: max-line-length
      url = `${this.baseUrl}?portal=facilisight&token=${token}&lastViewedDashboard=${lastViewedDashboardId}&userId=${userData.userId}&preferredLanguage=${userPrefLangauge}`;
    } else {
      url = `${this.baseUrl}?portal=facilisight&token=${token}&userId=${userData.userId}&preferredLanguage=${userPrefLangauge}`;
    }
    
    this.isVofm = this.authService.isUserViewOnly();
    url += `&isVofm=${this.isVofm}`;

    if (this.siteRef) {
      url += `&siteRef=${this.siteRef}`;
    }
    else{
      this.helperService.isDashboardPage$.next(true);
    }

    
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngAfterViewInit(){
    let loadTime = Date.now() - this.analyticsService.getPageLoadStartTime();
    this.analyticsService.pageLoadTime(loadTime);
  }

  @HostListener('window:message', ['$event'])
  onMessage(event) {
    this.receiveMessage(event);
  }
/**
 * This method sets the localStorage value of dashboardId for the key returned from decideLocalStorageKey method
 */  
  receiveMessage(event) {
    const pamUrl = this.configService.getConfig('pamUrl').replace(/\/$/, '');   //Removing the slash in the end of the URL
    if (event.origin.includes(pamUrl)) {
      try {
        const payload = JSON.parse(event.data);
        if (payload?.dashboardId && payload.dashboardId != this.userPreferenceData?.heatmapPreferences?.pamLastViewed) {
          this.setUserPreferencesData(payload.dashboardId);
        } else {
          if (payload?.mode == 'edit' || payload?.mode == 'add') {
            this.localStorage.setItem('Recently_Add_Or_Edit_PAM_Widget_Data', JSON.stringify(payload));
          } else if(payload?.mode == 'delete') {
            this.localStorage.removeItem(payload?.key);
          }
        }
      } catch (err) {
        console.log('Parse Error with message from iFrame');
      }
    } else {
      // console.error('Bad Origin');
      return;
    }
  }

  ngOnDestroy() {
    const ele = document.querySelector('body');
    ele.style.overflowY = 'auto';
    this.deleteOrSaveTheWidget();
  }

  deleteOrSaveTheWidget() {
    const data = JSON.parse(this.localStorage.getItem('Recently_Add_Or_Edit_PAM_Widget_Data'))
    if (data?.widgetSelections) {
      if (data?.widgetSelections?.chartType != 'trend' && data?.mode == 'edit') {
        this.pamWidgetService.saveWidget(data.widgetSelections, data?.widgetSelectionsTransformed).subscribe(
          _ => { 
            this.localStorage.removeItem('Recently_Add_Or_Edit_PAM_Widget_Data');
          });
      } else {
        this.pamWidgetService.deleteWidget(data?.widgetSelections?.widgetId).subscribe(() => {
          this.localStorage.removeItem('Recently_Add_Or_Edit_PAM_Widget_Data'); 
        });
      }
    }
  }

  async setUserPreferencesData(dashboardId) {
    try {
      await this.getUserPreferencesData();
    } catch (e) { }
    this.userPreferenceData.heatmapPreferences['pamLastViewed'] = dashboardId;
    this.userPref
      .setUserPreferenceData(this.userPreferenceData)
      .subscribe((res) => {
        this.userPreferenceData = res;
      });
  }
  async getUserPreferencesData() {
    return new Promise<void>((resolve, reject) => {
      this.userPref.getUserPreferenceData().subscribe({
        next: (res) => {
          this.userPreferenceData = res;
        },
        error: (err) => {
          reject(err);
        },
        complete: () => {
          resolve();
        }
      });
    });
  }
}
