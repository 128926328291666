import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { SharedModule } from '@/shared/shared.module'
import { Routes, RouterModule } from '@angular/router';
import { HeatmapLayoutComponent } from './components/heatmap-layout/heatmap-layout.component';
import { HeatmapHeaderComponent } from './components/heatmap-header/heatmap-header.component';
import { FileService, ReconfigurationModule, PucDirectivesModule, SortOrderComponent, VinMentionModule, SummaryConfigurationModule, AIDashboardModule, HeatmapFilterModule, PointSummaryModule, AnalyticsModule, HeatmapNotesModule, AlertModule } from '@75f/portal-ui-components';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ChatModule } from '@75f/chat-ui';

const routes: Routes = [
    { path: '', component: HeatmapLayoutComponent }
];

@NgModule({
    declarations: [
        HeatmapLayoutComponent,
        HeatmapHeaderComponent,
    ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        NgxSkeletonLoaderModule,
        FormsModule,
        SharedModule,
        NgxDaterangepickerMd.forRoot(),
        PucDirectivesModule,
        SummaryConfigurationModule,
        ReconfigurationModule,
        VinMentionModule,
        ChatModule,
        AlertModule,
        AIDashboardModule,
        HeatmapFilterModule,
        HeatmapNotesModule,
        PointSummaryModule,
        AnalyticsModule
    ],
    providers: [
        FileService
    ],
    entryComponents:[SortOrderComponent]
})
export class HeatmapListModule { }
