import { Component, OnInit, ViewChild, ViewEncapsulation, Input, SecurityContext, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, Validators } from '@angular/forms';
import {
    AlertService, LoaderService, UserService,
    UserManagementService,ConfirmModalComponent, ArrayUtil, CountriesService, SiteService
} from '@75f/portal-ui-components';

import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Subject } from 'rxjs/internal/Subject';
import { UserManagementModalComponent } from '../user-management-modal/user-management-modal.component';
import { HelperServiceFacilisight } from '@/shared/_services/helper.service';
export function CustomPaginator() {
    const customPaginatorIntl = new MatPaginatorIntl();
    customPaginatorIntl.itemsPerPageLabel = 'rows per page';
    return customPaginatorIntl;
}
@Component({
    selector: 'fs-user-management-table',
    templateUrl: './user-management-table.component.html',
    styleUrls: ['./user-management-table.component.scss'],
    providers: [
        { provide: MatPaginatorIntl, useValue: CustomPaginator() }
    ],
    encapsulation: ViewEncapsulation.None
})
export class UserManagementTableComponent implements OnInit, OnChanges {
    length;
    
    pageSize = 8;
    searchVal : string ='';
    //@ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('searchTextField', { static: false }) searchTextField: ElementRef;

    isLoading: boolean = false;
    searchText: any;
    // pageSizeOptions: number[] = [2, 8, 16, 24, 32];
    pageEvent: PageEvent;
    subscriptions: any = {};
    columns: Array<any> = [
        {
            key: 'role',
            label: 'Role',
            hasSort: true
        },
        {
            key: 'certificationLevel',
            label: 'Certification Level',
            hasSort: false
        },
        {
            key: 'firstname',
            label: 'First Name',
            hasSort: true
        },
        {
            key: 'lastname',
            label: 'Last Name',
            hasSort: true
        }, {
            key: 'email',
            label: 'Email'
        },
        {
            key: 'country',
            label: 'Country'
        },
        {
            key: 'phone',
            label: 'Phone No.'
        },
        {
            key: 'date',
            label: 'Date',
            hasSort: true
        },
        // {
        //     key: 'unit',
        //     label: 'Unit',
        // },
        {
            key: 'action',
            label: 'Actions'
        }
    ];
    @Input() siteId: string = '';
    @Input() hasSort: boolean = true;
    @Input() showAddUser: boolean;
    @Input() userId: any;
    userForm: any;
    searchTextChanged = new Subject();
    filteredList: any;
    usersData: any = [];
    popUpPosition: string;
    temperatureUnit: any;
    energyConsumptioUnit: any;
    airflowVolumeUnit: any;
    airPressureUnit: any;
    waterFlowUnit: any;
    waterPressureUnit: any;
    hideUnitsForOccupantUser: boolean = false;
    @Input()
    get userName(): any {
        return this._userName;
    }
    set userName(val: any) {
        this._userName = val;
        this.searchText = val;
    }
    _userName: any;

    @Input()
    get data() {
        return this._data;
    }
    set data(val: []) {
        this._data = val;
    }
    _data;
    hasSearch: boolean = false;
    sortVal = {
        active: '',
        direction: ''
    }
    zoneAssignmentChangeVal = [];
    countryList = [];
    exampleNumber:any;
    phoneLength:any;
    constructor(
        public dialog: MatDialog,
        private userMangementService: UserManagementService,
        private dom: DomSanitizer,
        private fb: FormBuilder,
        private userService: UserService,
        public alertService: AlertService,
        private loaderService: LoaderService,
        public countriesService: CountriesService,
        private siteService: SiteService,
        public helperService: HelperServiceFacilisight
    ) {
        this.userForm = this.fb.group({
            "firstName": ["", Validators.required],
            "lastName": [""],
            "certificationLevel":[""],
            "emailId": ["", [Validators.required, Validators.email]],
            "phoneNum": ["", [Validators.required]],
            "country": ["", [Validators.required]]
        });
    }
   

    ngOnInit() {
        this.subscriptions['searchText'] = this.searchTextChanged.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe((search) => {
            this.searchText = search;
            this.filterUsers();
        });
        this.isLoading = true;
        this.countryList = this.countriesService.getCountries();
        //Updating the global scroll bar behaviour
        (<HTMLElement>document.getElementsByClassName('fs-content')[0]).style.overflow = 'auto';

    }

    search(searchText) {
        this.searchTextChanged.next(searchText.trim());
    }
/**

 * This method filters users based on their first name, last name and date.

 */
    filterUsers() {
        this.filteredList = ArrayUtil.clone(this.usersData);
        // get the search keyword
        let search = this.searchText || '';
        search = search.toLowerCase();
        // filter the banks
        if (search.length > 0)
            this.filteredList = this._data.filter(item => {
                return (item.personalInfo.firstName ? item.personalInfo.firstName.toLowerCase().indexOf(search) > -1 : false) || (item.personalInfo.lastName ? item.personalInfo.lastName.toLowerCase().indexOf(search) > -1 : false) || item.emailId.toLowerCase().indexOf(search) > -1
            })

        if (this.sortVal.active && this.sortVal.direction) {
            if (this.sortVal.active == 'firstname') {
                this.filteredList = this.filteredList.sort((a, b) => {
                    const order = this.sortVal.direction == 'asc' ? 1 : -1
                    let comparison = this.compareUser(a, b, 'firstName')
                    return comparison * order;
                })
            } else if (this.sortVal.active == 'lastname') {
                this.filteredList = this.filteredList.sort((a, b) => {
                    const order = this.sortVal.direction == 'asc' ? 1 : -1
                    let comparison = this.compareUser(a, b, 'lastName')
                    return comparison * order;
                })

            } else if (this.sortVal.active == 'date') {
                this.filteredList = this.filteredList.sort((a: any, b: any) => {
                    const order = this.sortVal.direction == 'asc' ? 1 : -1
                    return (Date.parse(b.createdAt) - Date.parse(a.createdAt)) * order;
                })

            } else if(this.sortVal.active == 'role') {
                this.filteredList = this.filteredList.sort((a, b) => {
                    const order = this.sortVal.direction == 'asc' ? 1 : -1
                    return (a.role > b.role ? 1 : -1) * order;
                })
            }
        }
        this.isLoading = false;
    }

/* * This method clears the search text and resets the filtered list to the original list. */
    clearFilterSearch() {
        this.filteredList = ArrayUtil.clone(this.usersData);
        this.searchText = ''; 
    }
    
/**

 * This method compares users based on their personal info.

 */
    compareUser(a, b, key) {
        const userA = a.personalInfo[key] ? a.personalInfo[key].toUpperCase() : '';
        const userB = b.personalInfo[key] ? b.personalInfo[key].toUpperCase() : '';

        let comparison = 0;
        if (userA > userB) {
            comparison = 1;
        } else if (userA < userB) {
            comparison = -1;
        }
        return comparison;
    }

    sortData(sort: Sort) {
        this.sortVal = sort;
        this.filterUsers();
    }

    formatCertificationLevel(level){
        let certificationLevel;
        switch (level) {
            case 'L0':
                certificationLevel = 'Non-Certified';
                break;
            case 'L1':
                certificationLevel = 'Installer L1';
                break;
            case 'L2':
                certificationLevel = 'Installer L2';
                break;
            case 'L3':
                certificationLevel = 'Integrator';
                break;
        }

        return certificationLevel;
    }

    ngOnDestroy() {
        const self = this;
        Object.keys(self.subscriptions).forEach(e => {
            self.subscriptions[e].unsubscribe();
        });
        //Resetting the global scrollbar behaviour to default once the component is destroyed
        (<HTMLElement>document.getElementsByClassName('fs-content')[0]).style.overflow = 'visible';
    }

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;

    }

    editUser(item) {
        const self  = this
        const isOccupant = item.roleCode == 'occupant' ? true: false;
        const dialogRef = self.dialog.open(UserManagementModalComponent, {
            width: '75%',
            height: 'auto',
            panelClass: 'update-user-modal',
            data: { manager: item, isOccupant, siteId: this.siteId } 
        });
       
        this.data.map((user: any) => user['isEditable'] = false);
        item['isEditable'] = true;
        (<HTMLElement>document.getElementsByClassName('fs-content')[0]).style.overflow = 'hidden';
        this.userForm.controls.firstName.setValue(item.personalInfo.firstName);
        this.userForm.controls.lastName.setValue(item.personalInfo.lastName);
        this.userForm.controls.emailId.setValue(item.emailId);
        this.userForm.controls.phoneNum.setValue(item.personalInfo.phoneNum);
        this.userForm.controls.country.setValue(item.personalInfo.country);
        dialogRef.afterClosed().subscribe((re) => {
            (<HTMLElement>document.getElementsByClassName('fs-content')[0]).style.overflow = 'auto';
        });

    }
    closeEdit(item) {
        item['isExpanded'] = false;
        item['isEditable'] = false;
        this.userForm.reset();
        this.userForm.markAsPristine();
    }

    onMouseHover(event, units, role): void{
        let data = units.split(',');
       this.popUpPosition = 'top';
       this.temperatureUnit = data[0];
       this.energyConsumptioUnit = data[1];
       this.airflowVolumeUnit = data[2];
       this.airPressureUnit = data[3];
       this.waterPressureUnit = data[4];
       this.waterFlowUnit = data[5];
       this.hideUnitsForOccupantUser = role == 'occupant' ? true : false;
    }

    updateSuccess(item) {
        this.closeEdit(item);
        this.alertService.success('user updated successfully');
        this.userMangementService.userChanged$.next(true);
    }

    deleteUser(item) {

        item['isEditable'] = false;
        this.confirmUserDelete(item)

    }

    confirmUserDelete(user) {
        const self = this;

        const dialogRef = self.dialog.open(ConfirmModalComponent, {
            panelClass: 'fs-mat-dialog-container',
            width: '500px'
        });
        let msg = this.getUserDeletionConfirmMsg(user.isReadOnly ? 'viewOnlyFM' : user.roleCode, user.emailId);
        const htmlContent = msg;
        dialogRef.componentInstance.title = 'Confirm Delete';
        dialogRef.componentInstance.htmlContent = this.dom.sanitize(SecurityContext.HTML, htmlContent);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.deleteUserApi(user);
            }
        });
    }

    getUserDeletionConfirmMsg(type, emailId) {
        if (type == 'facilityManager') {
            return `<div>Are you sure you want to remove ${'facility manager'} access from <span class="color-primary">${emailId}</span>. </div>`;
        } else if (type == 'installer') {
            return `<div>Are you sure you want to remove ${'installer'} access from <span class="color-primary">${emailId}</span>. </div>`;
        } else if (type == 'occupant') {
            return `<div>Are you sure you want to remove ${'occupant'} access from <span class="color-primary">${emailId}</span>. </div>`;
        }else if (type == 'viewOnlyFM') {
            return `<div>Are you sure you want to remove ${'View Only FM'} access from <span class="color-primary">${emailId}</span>. </div>`;
        }
    }
/**

 * This method delete users from API and calls alterservice

 */

    deleteUserApi(user) {
        const self = this;
        let payload = {
            "userModifications": [
                {
                    "userId": user.userId,
                    "role": user.roleCode,
                    "op": "remove",
                    "isPrimary": user?.isPrimary ?? false
                }
            ]
        };
        self.loaderService.active(true);
        self.subscriptions['deleteUser'] = self.userMangementService.deleteSiteUser(this.siteId, payload).subscribe((res: any) => {
            self.loaderService.active(false);
            if (res.error) {
                this.alertService.error("Unable to delete user ");
                return;
            }
            this.alertService.success("User deleted successfully");
            this.userMangementService.userChanged$.next(true);
        }, err => {
            self.loaderService.active(false);
            this.alertService.error("Unable to delete user ")
        })

    }

    collapseUserView(item) {
        item['isExpanded'] = false;
    }

    expandUserView(item) {
        item['isExpanded'] = true;
        this.getOccupantUserZones(item);
    }

    getOccupantUserZones(item) {
        const self = this;
        self.subscriptions['getZones'] = this.userService.getOccupantUserZonesBySite(item.userId,this.siteId).subscribe((res) => {
            const floors = [];
			const zoneIds = [];
			res['zones'].forEach(element => {
				floors.push(element.floor_id.toString().split('@')[1]);
				zoneIds.push(element.zone_id.toString().split('@')[1]);
			});
			this.getUniquePointIds(floors,zoneIds,item);
        });
    }

    getUniqueIds(array) {
        return array.filter((c, index) => {
            return array.indexOf(c) === index;
        });
    }

    getUniquePointIds(floors, zoneIds, item) {
        const floorIds = this.getUniqueIds(floors) || [];
        const uniqueZoneIds = this.getUniqueIds(zoneIds) || [];
        let pointIds = [];
        pointIds = pointIds.concat(floorIds);
        pointIds = pointIds.concat(uniqueZoneIds);
        this.siteService.getReadByIdMany(pointIds).subscribe(res => {
            item['zoneList'] = [];
            item['zoneList'] = this.helperService.getGroupedZones(res, item);
        })
    }

    checkUserCanEdit(user) {
        if (!this.showAddUser) {
            if (user.userId != this.userId) {
                return true;
            }
            return !(!this.showAddUser && user.isReadOnly == 1);
        }
        return false;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.showAddUser?.currentValue) {
            this.showAddUser = changes?.showAddUser?.currentValue;
        }
        if (changes.data?.currentValue) {
            this.usersData = ArrayUtil.clone(changes.data.currentValue);
            this.isLoading = true;
            this.filterUsers();
        }
    }
}
