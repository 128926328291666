import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomDashboardComponent } from './custom-dashboard/custom-dashboard.component';
import { RouterModule, Routes } from '@angular/router';
import { DashboardDeactivateGuard } from '@75f/portal-ui-components';

const routes: Routes = [
  { path: '', component: CustomDashboardComponent ,
    canDeactivate: [DashboardDeactivateGuard]
  },
  
];

@NgModule({
  declarations: [
    CustomDashboardComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ]
})
export class CustomDashboardsModule { }
