<div class="row">
    <div class="col-xs-6">
        <div class="search"> 
            <puc-common-search [inputPlaceholder]="'Search for First Name/Last Name/Email'" (searchText)="search($event)" (clearSearch)="clearFilterSearch()"></puc-common-search>
        </div>
     </div>
    <div class="col-xs-6">
        <!-- <mat-paginator class="user-tbl-paginator" #paginator [length]="length" [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions" (page)="pageEvent=$event; onChangePage($event)">
        </mat-paginator> -->
    </div>
</div>
<div class="row" [formGroup]="userForm">
    <div class="col-xs-12 user-management-table">
        <div class="user-management-t-head">
            <ng-container>

                <ul class="d-row" matSort (matSortChange)="sortData($event)">
                    <ng-container *ngFor="let column of columns">
                        <ng-container *ngIf="column?.hasSort;else noSortCol">
                            <li mat-sort-header="{{column.key}}">
                                {{column?.label}}
                            </li>
                        </ng-container>

                        <ng-template #noSortCol>
                            <li>
                                {{column?.label}}
                            </li>
                        </ng-template>

                    </ng-container>
                </ul>


            </ng-container>

        </div>
        <div class="user-management-t-body plain-tbl">
            <!-- <ng-container *ngFor="let manager of filteredList;let i=index">
                <ng-container [ngTemplateOutlet]="userTblRows"
                    [ngTemplateOutletContext]="{ $implicit: manager,index:i}">
                </ng-container>

               
            </ng-container> -->

            <ng-container *ngFor="let manager of filteredList;let i=index">
                <ng-container *ngIf="manager['roleCode']=='occupant';else elsTpl">

                    <mat-accordion [multi]="true">
                        <mat-expansion-panel (closed)="collapseUserView(manager)" hideToggle
                            (opened)="expandUserView(manager)" [expanded]="manager.isExpanded">
                            <mat-expansion-panel-header [expandedHeight]="'30px'" [collapsedHeight]="'30px'"
                                (keydown.Space)="$event.stopImmediatePropagation();">
                                <ng-container [ngTemplateOutlet]="userTblRows"
                                    [ngTemplateOutletContext]="{ $implicit: manager,index:i}">
                                </ng-container>

                            </mat-expansion-panel-header>

                            <div class="exp-panel-content">
                                <div class="row">
                                    <div class="col-xs-12 m-b-10 p-l-10">
                                        <div class="col-xs-12 m-b-10" *ngIf="manager['roleCode']=='occupant'">
                                            <h2 class="text-uppercase p-5-0 sub-head">Zone Access</h2>
                                            <ul class="list-none d-flex flex-direction-row building-info flex-wrap">
                                                <li class="item d-flex  m-l-10 m-r-10"
                                                    *ngFor="let site of manager?.zoneList;let i =index">
                                                    <div class="ctnr">
                                                        <div *ngFor="let floor of site?.floors" class="floor">
                                                            {{floor?.floorName}}
                                                            <div class="name notranslate">
                                                                <span *ngFor="let zone of floor?.zones; let i=index">{{i
                                                                    != 0 ? ', ':''}}{{zone?.zoneName}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </mat-expansion-panel>
                    </mat-accordion>
                </ng-container>
                <ng-template #elsTpl>
                    <ng-container [ngTemplateOutlet]="userTblRows"
                        [ngTemplateOutletContext]="{ $implicit: manager,index:i}">
                    </ng-container>
                </ng-template>
            </ng-container>


            <ng-template #userTblRows let-manager let-i="index">
                <ul class="d-flex w-100 p-5 d-row">
                    <li class="d-flex justify-content-left align-items-center">
                        <div class="p-r-10" *ngIf="manager['roleCode']=='occupant'">
                            <span *ngIf="!manager['isExpanded']" class="fas fa-caret-right"></span>
                            <span *ngIf="manager['isExpanded']" class="expanded-color fas fa-sort-down"></span>
                        </div>
                        <div>
                            <div class="site-name">{{manager.isReadOnly ? 'View Only FM' : manager.role}}</div>
                        </div>
                    </li>
                    <li class="justify-content-center col-text-style align-items-center">
                        {{ formatCertificationLevel(manager?.certification_level) || '--'}}
                    </li>
                    <li class="justify-content-center col-text-style align-items-center"
                        [class.highlight]="manager.loggedUser ">
                        <div matTooltip="{{ manager?.personalInfo?.firstName }}" matTooltipPosition="above"
                            matTooltipClass="matTooltip" class="loggedUser firstName">
                            {{manager?.personalInfo?.firstName}}</div>
                    </li>
                    <li matTooltip="{{ manager?.personalInfo?.lastName }}" matTooltipPosition="above"
                        matTooltipClass="matTooltip"
                        class="justify-content-center col-text-style align-items-center lastname">
                        {{manager?.personalInfo?.lastName}}</li>
                    <li matTooltip="{{ manager?.emailId }}" matTooltipPosition="above" matTooltipClass="matTooltip"
                        class="justify-content-center col-text-style align-items-center email">
                        {{manager?.emailId}}</li>

                    <li class="justify-content-center col-text-style align-items-center country">
                        {{(manager?.personalInfo?.country) || '--'}}
                    </li>
                    <li class="justify-content-center col-text-style align-items-center">
                        {{(manager?.personalInfo?.phoneNum) || '--'}}
                    </li>
                    <li class="justify-content-center col-text-style align-items-center">
                        {{manager?.createdAt | pucDate:'D/MM/YY- h:mm:ss A'}}</li>
                    <!-- <li class="justify-content-center col-text-style align-items-center pointer unit"
                        (mouseover)="onMouseHover($event, manager.unitArray, manager['roleCode'])" [tippy]="tooltipTemplate"
                        placement="{{popUpPosition}}" [delay]="[100, 0]" [duration]="[100, 0]" arrow="true"
                        animation="scale">
                        {{manager?.unitArray}}
                    </li> -->
                    <li class="justify-content-center action-btns align-items-center">
                        <span class="fas fa-pen icons"
                            [class.disable]="((!manager.isEdit && !manager['isEditable'] && manager.isReadOnly == 0 ) || (checkUserCanEdit(manager)))"
                            (click)="$event.stopPropagation();editUser(manager)"></span>
                        <span class="fas fa-trash-alt icons"
                            [class.disable]="(!manager.isEdit && manager.isReadOnly == 0) || (checkUserCanEdit(manager))"
                            (click)="$event.stopPropagation();deleteUser(manager)"></span>
                    </li>
                </ul>
            </ng-template>
            <ng-template #tooltipTemplate>
                <div class="toolTip">
                    <ul class="unStyled">
                        <li>Temperature Unit : {{temperatureUnit}}</li>
                        <ng-container *ngIf="!hideUnitsForOccupantUser">
                            <li>Energy Consumption Unit : {{energyConsumptioUnit}}</li>
                            <li>Airflow Volume Unit : {{airflowVolumeUnit}}</li>
                            <li>Air Pressure Unit : {{airPressureUnit}}</li>
                            <li>Water Pressure Unit : {{waterPressureUnit}}</li>
                            <li>Water Flow Unit : {{waterFlowUnit}}</li>
                        </ng-container>

                    </ul>
                </div>
            </ng-template>
            <div *ngIf="filteredList?.length === 0 && !isLoading"  class="text-center m-5-a">No records found.</div>
            <div *ngIf="filteredList?.length === 0 && isLoading" class="text-center m-5-a">
                <ngx-skeleton-loader [theme]="{ 'border-radius': '0', height: '18.5px'}" *ngFor="let repeat of [0,1,2]"></ngx-skeleton-loader>
            </div>

        </div>
    </div>
</div>