import { HelperServiceFacilisight } from '@/shared/_services/helper.service';
import { ConfigurationService, ObjectUtil, SiteService, MultiSelectCheckboxComponent, forbiddenIfSplCharAtStartValidator } from '@75f/portal-ui-components';
import { UserManagementService, LoaderService, AlertService, UnitService, UserService,LanguageOptions } from '@75f/portal-ui-components';
import { Component, Inject, OnInit, Injector, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'fs-user-management-modal',
	templateUrl: './user-management-modal.component.html',
	styleUrls: ['./user-management-modal.component.scss'],
	// encapsulation: ViewEncapsulation.None
})
export class UserManagementModalComponent implements OnInit {

	manager: any;
	data: any;
	userForm: FormGroup;
	showOrgs: boolean = false;
	showBuildings: boolean = true;
	showPreferences: boolean = true;
	showOtherRoles: boolean = true;
	isSupport: boolean;
	showZones: boolean;
	countryList = [];
	assignmentChangeVal = [];
	zoneAssignmentChangeVal = [];
	orgAssignmentChangeVal = [];
	emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6}$";
	namePattern = /^(?:[A-Za-z0-9\s]+)(?:[A-Za-z0-9_-\s]*)$/;
	existingEmailId: any;
	subscriptions: any = {};
	filteredList: any;
	loaderService: LoaderService;
	unitService: UnitService;
	alertService: AlertService;
	_data;
	isOccupant: boolean = false;
	assignedZones = [{
		siteId: "site1",
		floorName: "Floor A",
		zoneId: "zone1",
		zoneName: "Zone A",
	},
	{
		siteId: "site1",
		floorName: "Floor B",
		zoneId: "zone1",
		zoneName: "Zone B",
	}];


	featureItems = [
		'Audit Trail',
		'Custom Alerts',
		'Custom Analytics',
		'Custom Parameters',
		'Custom Summary Table',
		'Energy Configuration',
		'Notes',
		'Site Manager',
		'Site Sequencer',
		'Remote Access',
		'Building Options'
	];
	filteredFeatureItems: any;
	isNonCertifiedUser: boolean = false;

	assignedZoneClicked: boolean = false;
	duplicateGroupedZones = [];
	groupedZones: Array<any> = [];
	selectedZones = [];
	siteId = '';
	isInstallerSelected: boolean = false;
	@ViewChild('multiSelect', { static: false }) multiSelectBox: MultiSelectCheckboxComponent
	appName: any;
	laguageOptions: { name: string; value: string; }[];

	constructor(
		public dialogRef: MatDialogRef<UserManagementModalComponent>,
		@Inject(MAT_DIALOG_DATA) public check: any, public fb: FormBuilder,
		private userMangementService: UserManagementService,
		private userService: UserService,
		private injector: Injector,
		public siteService: SiteService,
		public helperService: HelperServiceFacilisight,
		public configService: ConfigurationService
	) {
		this.appName = this.configService.getConfig('appDisplayName');
		this.loaderService = injector.get<LoaderService>(LoaderService);
		this.unitService = injector.get<UnitService>(UnitService);
		this.alertService = injector.get<AlertService>(AlertService);
		this.data = ObjectUtil.deepCopy(this.check['manager']);
		this.userForm = this.fb.group({
			"firstName": ["", [Validators.required, Validators.pattern(this.namePattern), forbiddenIfSplCharAtStartValidator]],
			"lastName": ["", [Validators.pattern(this.namePattern), forbiddenIfSplCharAtStartValidator]],
			"certificationLevel": [""],
			"emailId": ["", [Validators.required, Validators.email]],
			"temperature": ["", Validators.required],
			"energyConsumption": ["", Validators.required],
			"airflowVolume": ["", Validators.required],
			"airPressure": ["", Validators.required],
			"waterPressure": ["", Validators.required],
			"waterFlow": ["", Validators.required],
			"language": ["", Validators.required]
		});
		if (this.check?.isOccupant) {
			this.isOccupant = true
			this.selectedZones = this.data?.selectedZones;
			this.siteId = this.check.siteId;
			this.userForm.addControl('zones', new FormControl([], Validators.required));
			this.getOccupantUserZones();
			this.getZonesBySiteRef();
		}
		this.userForm.controls.firstName.setValue(this.data?.personalInfo.firstName);
		this.userForm.controls.lastName.setValue(this.data?.personalInfo.lastName);
		this.userForm.controls.certificationLevel.setValue(this.updateCertificationLevel(this.data?.certification_level));
		this.userForm.controls.emailId.setValue(this.data?.emailId);
		this.userForm.controls.temperature.setValue(this.data?.userPreferences?.temperatureUnit || this.unitService.temperatureUnitListArray[1]);
		this.userForm.controls.energyConsumption.setValue(this.data?.userPreferences?.energyConsumptionUnit || this.unitService.energyConsumptionUnitListArray[0]);
		this.userForm.controls.airflowVolume.setValue(this.data?.userPreferences?.airflowVolumeUnit || this.unitService.airflowVolumeUnitListArray[0]);
		this.userForm.controls.airPressure.setValue(this.data?.userPreferences?.airPressureUnit || this.unitService.airPressureUnitListArray[0]);
		this.userForm.controls.waterPressure.setValue(this.data?.userPreferences?.waterPressureUnit || this.unitService.waterPressureUnitListArray[0]);
		this.userForm.controls.waterFlow.setValue(this.data?.userPreferences?.waterFlowUnit || this.unitService.waterFlowUnitListArray[0]);
		this.userForm.controls.language.setValue(this.data?.userPreferences?.language || "en");
	}

	ngOnInit(): void {
		this.existingEmailId = this.data?.emailId;
		this.laguageOptions = LanguageOptions;
		if (this.data?.roleCode === 'installer') {
			this.isInstallerSelected = true;
		} else {
			this.isInstallerSelected = false;
		}
	}

	updateCertificationLevel(level: string) {

		let certificationLevel;
		switch (level) {
			case 'L0':
				certificationLevel = 1;
				break;
			case 'L1':
				certificationLevel = 2;
				break;
			case 'L2':
				certificationLevel = 3;
				break;
			case 'L3':
				certificationLevel = 4;
				break;
		}
		return certificationLevel;
	}

	userAssignmentChange(value) {
		this.zoneAssignmentChangeVal = value.modifiedData || [];
		if (value && value.submit) {
			let item = this.filteredList;
			this.updateUser(item);
		}
	}

	updateUser(item) {
		if (this.userForm.valid) {
			const zones = this.generatePayloadForOccupant();

			this.loaderService.active(true);
			let payload = {
				firstName: this.userForm.controls.firstName.value,
				lastName: this.userForm.controls.lastName.value,
				emailId: this.userForm.controls.emailId.value,
				userPreferences: {
					temperatureUnit: this.userForm.controls.temperature.value,
					energyConsumptionUnit: this.userForm.controls.energyConsumption.value,
					airflowVolumeUnit: this.userForm.controls.airflowVolume.value,
					airPressureUnit: this.userForm.controls.airPressure.value,
					waterPressureUnit: this.userForm.controls.waterPressure.value,
					waterFlowUnit: this.userForm.controls.waterFlow.value,
					language: this.userForm.controls.language.value
				},
				zones: zones,
			}

			if (this.data?.isReadOnly) {
				payload['role'] = "facilityManager",
					this.siteId = this.check.siteId;
				payload['sites'] = [{
					"siteId": this.siteId,
					"isReadOnly": true
				}]
			}

			if (item.emailId != this.userForm.controls.emailId.value) {
				payload['existingEmailId'] = item.emailId
			}
			this.subscriptions['updateusers'] = this.userService.editUser(payload).subscribe((res: any) => {
				this.loaderService.active(false);
				if (res.success) {

					this.updateSuccess(item);
				} else {
					this.alertService.error(res.msg);
				}
			}, err => {
				this.loaderService.active(false);
				this.alertService.error(err?.error?.msg
					|| 'Something went wrong, please try again');
			});

		}
	}

	addAlreadyAssignedZones(payload) {

		this.data?.zoneList?.forEach(element => {
			element.floors.forEach(floor => {
				floor.zones.forEach(zone => {
					payload.push({
						"siteId": this.siteId,
						"floorId": `@` + floor.floorId.toString().split(':')[1],
						"floorName": floor.floorName,
						"zoneId": `@` + zone.zoneId.toString().split(':')[1],
						"zoneName": zone.zoneName,
						"op": "add"
					});
				});
			});
		});
		return payload;
	}

	generatePayloadForOccupant() {
		let payload = [];
		if (this.multiSelectBox?.selectedItems?.length) {
			this.multiSelectBox.selectedItems.forEach(floor => {
				floor.children.forEach(zone => {
					payload.push({
						"siteId": this.siteId,
						"floorId": `@` + floor.id.toString().split(':')[1],
						"floorName": floor.name,
						"zoneId": `@` + zone.id.toString().split(':')[1],
						"zoneName": zone.name,
						"op": "add"
					})
				});
			});
			const arr = [];
			this.groupedZones.forEach(r => {
				this.data.zoneList.forEach(i => {
					const found = i.floors.find(flr => flr.floorId == r.id);
					if (found) {
						found.zones.forEach(j => {
							const foundZone = payload.find(p => p.zoneId.toString().split('@')[1] == j.zoneId.toString().split(':')[1]);
							if (foundZone) {
								// do nothing
							} else {
								arr.push({
									"siteId": this.siteId,
									"floorId": `@` + found.floorId.toString().split(':')[1],
									"floorName": found.floorName,
									"zoneId": `@` + j.zoneId.toString().split(':')[1],
									"zoneName": j.zoneName,
									"op": "remove"
								})
							}
						});
					}
				});
			});
			payload = payload.concat(arr);
		} else {
			payload = this.addAlreadyAssignedZones(payload);
		}
		return payload;
	}

	updateSuccess(item) {
		this.closeEdit(item);
		this.alertService.success('user updated successfully');
		this.userMangementService.userChanged$.next(true);
	}

	closeEdit(item) {
		item['isExpanded'] = false;
		item['isEditable'] = false;
		this.userForm.reset();
		this.userForm.markAsPristine();
		if (this.dialogRef) {
			this.dialogRef.close();
		}
	}

	formValidator(form: FormGroup): void {
		form.controls.emailId.markAsTouched();
		form.controls.firstName.markAsTouched();
	}

	onAssignZoneClick() {
		this.assignedZoneClicked = !this.assignedZoneClicked;
	}

	getSelectedItems(event) {
		this.selectedZones = event;
	}

	getZonesBySiteRef() {
		const query = `ver:"2.0"\nfilter\n"siteRef==${this.siteId} and ((profile and roomRef and not system) or (room or floor))"`
		this.siteService.queryAssist(query, `v2/read`).subscribe(res => {
			let rooms = [];
			let profiles = [];
			const floors = [];
			res.rows.forEach(item => {
				if (item.room) {
					rooms.push(item);
				} else if (item.profile) {
					profiles.push(item);
				} else if (item.floor) {
					floors.push(item);
				}
			});
			profiles = profiles.filter(profile => {
				if (profile.profile != "PLC" && profile.profile != 'EMR' && profile.profile.indexOf('SENSE') == -1 && profile.profile.indexOf('TEMP_INFLUENCE') == -1 && profile.profile.indexOf('MONITORING') == -1) {
					return profile;
				}
			});
			rooms = rooms.filter(r => profiles.some(_item => _item.roomRef == r.id));
			this.groupedZones = floors.map(r => {
				return {
					id: r.id,
					name: r.dis,
					value: r.id,
					children: rooms.map(data => {
						if (data.floorRef == r.id) {
							return {
								id: data.id,
								name: data.dis,
								value: data.id
							};
						}
					}).filter(zone => zone)
				};
			});
			this.duplicateGroupedZones = ObjectUtil.deepCopy(this.groupedZones);
		})
	}

	getUniqueIds(array) {
		return array.filter((c, index) => {
			return array.indexOf(c) === index;
		});
	}


	getOccupantUserZones() {
		const self = this;
		self.subscriptions['getZones'] = this.userService.getOccupantUserZonesBySite(this.data.userId, this.siteId).subscribe((res) => {
			const floors = [];
			const zoneIds = [];
			res['zones'].forEach(element => {
				floors.push(element.floor_id.toString().split('@')[1]);
				zoneIds.push(element.zone_id.toString().split('@')[1]);
			});
			this.getUniquePointIds(floors, zoneIds);
		});
	}

	getUniquePointIds(floors, zoneIds) {
		const floorIds = this.getUniqueIds(floors) || [];
		const uniqueZoneIds = this.getUniqueIds(zoneIds) || [];
		let pointIds = [];
		pointIds = pointIds.concat(floorIds);
		pointIds = pointIds.concat(uniqueZoneIds);
		this.siteService.getReadByIdMany(pointIds).subscribe(res => {
			this.data['zoneList'] = [];
			this.data['zoneList'] = this.helperService.getGroupedZones(res, this.data);
			const arr = []
			res.rows.forEach(element => {
				if (element.room) {
					arr.push(element.id);
				}
			});
			this.userForm.controls['zones'].patchValue(arr);
		});
	}

	reassignClicked() {
		this.multiSelectBox.onChildChange();
		this.userForm.markAsTouched();
		this.userForm.markAsDirty();
		this.multiSelectBox.matSelect.open();
	}

}
