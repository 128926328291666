<div class="row alertpage">
    <div class="col-md-3 alertpage__sidemenu" *ngIf="displayAllSite">
        <ul *ngIf="siteList.length; else navSkeleton">
            <li *ngIf="displayAllSite" (click)="selecteSite(null,-1)"
                [class.active]="selectedRow == -1" [title]="'All Buildings'">
                <span> <i class="fas fa-map-marker-alt"></i></span>
                <span style="padding-bottom: 2%;">All buildings</span>
            </li>
            <li *ngFor="let site of siteList;let i = index" (click)="selecteSite(site.siteId,i)"
                [class.active]="i == selectedRow" [title]="site.siteName">
                <span> <i class="fas fa-map-marker-alt"></i></span>
                <span style="padding-bottom: 2%;" class="notranslate">{{site.siteName}}</span>
            </li>
        </ul>
        <ng-template #navSkeleton>
            <ul>
                <li *ngFor="let number of [0,1,2,3,4,5,6]">
                    <ngx-skeleton-loader></ngx-skeleton-loader>
                </li>
            </ul>
        </ng-template>
    </div>

    <div [ngClass]="{'col-md-9': displayAllSite , 'col-md-11': !displayAllSite, 'margin-auto': !displayAllSite}">
        <div class="table-container d-flex flex-column alerts-table">
            <puc-alerts [siteRefs]="siteRefs" [showMute]="true" [siteTimeZones]="siteTimeZones" [allSiteSelected]="allSitesSelectedForAlerts" [isVofm] = "isVofm" [dispAlert]="displayAllSite">
            </puc-alerts>
        </div>

    </div>
</div>