<div class="dashboard-content">
    <div class="dashboard-content__building-list" (mouseleave)="hoverOutsideSitePanel()">
        <div>
            <div class="dashboard-content__building-list__searchbar">
                <input type="text" id="myInput" placeholder="Search for Organisation/Site Name" title="Building Name" [ngModel]="searchFieldInput" (ngModelChange)="onSiteSearch($event)" autocomplete="off"><i
                    class="fas fa-search search-icon" aria-hidden="true"></i>
            </div>
            <ul class="dashboard-content__building-list__buildingList" *ngIf="skeletonContentLoaded">
                <li *ngFor="let org of filteredbuildinglist">
                    <div class="orgname notranslate" id="{{org.orgId}}">
                        {{org.orgName}}
                    </div>
                    <ul>
                        <li class="site_container" *ngFor="let buildinginfo of org.sites" (click)="navToHeatmap(buildinginfo.id)" (mouseenter)="onMouseEnter(buildinginfo)" (mouseleave)="onMouseLeave()">
                            <p id="{{buildinginfo.id}}" class="notranslate">
                                {{buildinginfo.dis}}
                            </p>
                            <span>
                                {{buildinginfo.geoAddr}}
                                <span>
                                    {{buildinginfo.geoPostalCode}}
                                </span>
                            </span>
                        </li>
                        <hr>
                        <li *ngIf="AllSites != 0 && org.value == 0">
                            No result found
                        </li>
                        <li *ngIf="AllSites == 0">
                            <i class="fas fa-exclamation-triangle" aria-hidden="true"></i> User has no buildings assigned
                        </li>
                    </ul>
                </li>
            </ul>
            <!--Skeleton Conponent for builings list -->

            <ul class="dashboard-content__building-list__buildingList m-l-17" *ngIf="!skeletonContentLoaded">
                <li *ngFor="let site of mockBuildings">
                    <p class="skeleton-geoaddress">
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </p>
                    <span class="skeleton-postalcode">
                        <p>
                            <ngx-skeleton-loader></ngx-skeleton-loader>
                        </p>
                        <span class="skeleton-postalcode">
                            <p>
                                <ngx-skeleton-loader></ngx-skeleton-loader>
                            </p>
                        </span>
                    </span>
                    <hr>
                </li>
            </ul>
        </div>
    </div>
    <div class="dashboard-content__right-pane">
        <div class="dashboard-content__right-pane__top-section">
            <div class="dashboard-content__right-pane__top-section__buildings" *ngIf="skeletonContentLoaded">
                <div><i class="fas fa-building" aria-hidden="true"></i>SITES<span>{{ AllSites }}</span></div>
                <div><i class="fas fa-ticket-alt" aria-hidden="true"></i>CCUS<span>{{ AllCcus?.length }}</span></div>
                <div><i class="fas fa-th-large" aria-hidden="true"></i>ZONES<span>{{ AllRooms?.length }}</span></div>
            </div>

            <!--Skeleton Component-->
            <div class="dashboard-content__right-pane__top-section__buildings" *ngIf="!skeletonContentLoaded">
                <div><i class="fas fa-building" aria-hidden="true"></i>SITES<span class="skeleton-toppane">
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </span></div>
                <div><i class="fas fa-ticket-alt" aria-hidden="true"></i>CCUS<span class="skeleton-toppane">
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </span></div> 
                <div><i class="fas fa-th-large" aria-hidden="true"></i>ZONES<span class="skeleton-toppane">
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </span></div>
            </div>

            <div class="dashboard-content__right-pane__top-section__priority">
                <div><span class="low-count m-r-10">
                        <img src="./assets/images/low_alert_icon.svg" class="alert_low p-r-5" alt="Alter Image">{{lowAlertCount !==undefined?' '+ (lowAlertCount | pucDecimalPipe ): ''}}</span>Low
                </div>
                <div>
                    <span class="moderate-count m-r-10">
                        <i class="fas fa-exclamation-circle p-r-5" aria-hidden="true"></i>{{moderateAlertCount !== undefined?'
                        '+ (moderateAlertCount | pucDecimalPipe):''}}
                    </span>
                    Moderate
                </div>
                <div>
                    <span class="severe-count m-r-10"> <i
                            class="fas fa-exclamation-triangle p-r-5" aria-hidden="true"></i>{{severeAlertCount !== undefined?'
                        '+(severeAlertCount | pucDecimalPipe):''}}</span>
                    Severe
                </div>
            </div>
        </div>
        <div class="dashboard-content__right-pane__main">
            <div class="dashboard-content__right-pane__main__map" id="map">
            </div>
            <div class="dashboard-content__right-pane__main__rightbar">
                <div class="dashboard-content__right-pane__main__rightbar__comfort-index p-10">
                    <span>COMFORT INDEX</span>
                </div>
                <div class="dashboard-content__right-pane__main__rightbar__savings p-10">
                    <span>30 DAY AVERAGE</span>
                </div>
            </div>
        </div>
    </div>
</div>