import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import {
	SelectedBuildingService,
	CCUALERTService,
} from '@shared/_services';

import { Building, UserIntent } from '@/shared/_models';
import { map } from 'rxjs/operators';
import {
	HttpCancelService, LoaderService, SiteService, ObjectUtil,UnitService,
	HelperService, PubNubService, DeviceHelperService, DateUtil, VacationsService, ArrayUtil, ConfigurationService, IntrinsicSchedulesComponent, AuthenticationService
} from '@75f/portal-ui-components';
import { MatSelect } from '@angular/material/select';
import { Subscription } from 'rxjs/internal/Subscription';
import { AnalyticsService } from 'src/app/shared/_services/analytics.service';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FeatureToggleService } from '@75f/portal-ui-components';
dayjs.extend(utc);
dayjs.extend(timezone);

@Component({
	selector: 'fs-system-operations-layout',
	templateUrl: './system-operations-layout.component.html',
	styleUrls: ['./system-operations-layout.component.scss']
})
export class SystemOperationsLayoutComponent implements OnInit, OnDestroy {
	occStatusValues: string[] = ['Occupied', 'Unoccupied', 'Tenant Override'];
	checkForAdvanceAhuProfiles = ["vavAdvancedHybridAhuV2", "dabAdvancedHybridAhuV2"];
	checkForVavDabSystemExternalProfiles = ["vavExternalAHUController","dabExternalAHUController"];
	OccStatus: any;
	/** Skeleton Loader Variables**/
	mockBuildings: Array<any> = new Array(6);
	hideDisplayUIBtuText: boolean = false;
	hideDisplayUIModbusText:boolean = false;
	hideDisplayUIText: boolean = false;
	skeletonContentLoaded: boolean = false;
	showBtu = false;
	showModbus= false;
	showEquipPaired: boolean;
	showEmr = false;
	btuMeterType: string;
	modbusType:any;
	emrMeterType: string;
	btuTagsProfile = {
		profileType: 'modbus',
		profile: {
			name: 'BTU METER'
		}
	}
	/*****/

	oaoArcOptions: any = UserIntent.oaoArcOptions;
	oaoArcValue: number = 0;
	OaoShow: boolean = false;
	averageHumidity: any = {};
	cO2AllCCu: any = {};
	subscriptions: any = {};

	helperCancelSubscription: Subscription;
	getEntitySubscription: Subscription;
	getCcuSubscription: Subscription;
	getPointDataSubscription: Subscription;
	ccuSettingsDataSubscription: Subscription;
	systemReconfigProfileName: string;
	siteTz: any;
	ccusWithPairedZones = [];
	intrinsicScheduleData = {};
	systemPoints = [];
	private unsubscribe: Subject<void> = new Subject();
	showSchedulerOccupancy: boolean = true;
	messagingSubscribe: any;
	isVofm = false;
	iframeSrc: any;
	showRemoteAccess: boolean = false;
	modbusEquipId: any;
	query = '(scheduleType or vacation or (building and occupancy) or (zone and schedule and days) or (special and schedule) or room or floor or ccu or equip) and siteRef==@';

	ngOnDestroy() {
		this.helperService.clearData()
		this.cleanUp();
		this.helperCancelSubscription.unsubscribe();
		this.unsubscribe.next();
        this.unsubscribe.complete();
		//To clear the messaging subscribe[Internally we are calling the api for every 5 sec]
        clearInterval(this.messagingSubscribe);
	}


	cleanUp() {
		this.btuMeterType = '';
		this.emrMeterType = '';
		this.showBtu = false;
		this.showEmr = false;
		this.httpCancelService.cancelPendingRequests();
		this.helperService.clearData();
		this.defaultMinHumidity = 0;
		this.defaultMaxHumidity = 0;
		this.defaultCompensateHumidity = 0;
		this.defaultDemandResponse = 0;
		this.ccuSetting = {
			master: null,
			systemMode: null,
			status: null,
			desiredComfortIndex: 5,
			compensateHumidity: null,
			demandResponse: null,
			minHumidity: null,
			maxHumidity: null,
			equipStatus: null,
			occStatus: null,
			occupancyStatus: null,
			oao: null,
			smartPostpurge: null,
			smartPrepurge: null,
			enhancedVentilation: null,
		};

		if (this.getEntitySubscription)
			this.getEntitySubscription.unsubscribe();

		if (this.getCcuSubscription)
			this.getCcuSubscription.unsubscribe();

		if (this.getPointDataSubscription)
			this.getPointDataSubscription.unsubscribe();

		if (this.ccuSettingsDataSubscription)
			this.ccuSettingsDataSubscription.unsubscribe();

		clearInterval(this.messagingSubscribe);
	}

	pointIdSet: any = new Set();
	modeList = ['OFF', 'AUTO', 'COOL ONLY', 'HEAT ONLY'];
	ccuId: string;
	ahuRef: string;
	ccuEquipRef: string;
	siteRef: string;
	siteCcus: any = [];
	ccuSettingObj: any = [];
	statusMessage: string;
	ccuSetting: any = {
		master: null,
		systemMode: null,
		status: null,
		desiredComfortIndex: 5,
		compensateHumidity: null,
		demandResponse: null,
		minHumidity: null,
		maxHumidity: null,
		equipStatus: null,
		occStatus: null,
		supplyAirflowTempCurrent:null,
		supplyAirflowTempSetpoint:null,
		ductStaticPressureCurrent:null,
		ductStaticPressureSetPoint:null,
		occupancyStatus: null,
		oao: null,
		smartPostpurge: null,
		smartPrepurge: null,
		enhancedVentilation: null,
		demandResponseActivation:null,
	};
	rangeSliderHack = {
		2: 5,
		3: 4,
		4: 3,
		5: 2,
	};
	onSystemMode: boolean = false;
	buildingMaster: any = [];
	buildings: any = [];
	selectedBuilding: Building = {
		siteId: undefined,
		ccus: undefined,
		rooms: undefined,
		siteName: undefined
	};
	defaultMinHumidity: number;
	defaultMaxHumidity: number;
	defaultCompensateHumidity: number;
	defaultDemandResponse: number;
	desiredCIValue;
	desiredCISliderValue: any;
	userTempPreference:any;
	@ViewChild('CompensateHumidity', { static: false }) CompensateHumidity: any;
	@ViewChild('DemandResponse', { static: false }) DemandResponse: any;
	@ViewChild('intrinsicRef', { static: false }) IntrinsicSchedulesComponent: IntrinsicSchedulesComponent;
	humdityValues: any;
	ccuSettingCounter: number = 0;
	constructor(
		private deviceHelper: DeviceHelperService,
		public unitService:UnitService,
		public siteService: SiteService,
		public pubNubService: PubNubService,
		private loaderService: LoaderService,
		public helperService: HelperService,
		public ccuAlertService: CCUALERTService,
		public deviceHelperService: DeviceHelperService,
		public selectedBuildingService: SelectedBuildingService,
		public httpCancelService: HttpCancelService,
		public vacationsService: VacationsService,
		private analyticsService: AnalyticsService,
		public configService: ConfigurationService,
		public authService: AuthenticationService,
		private featureToggleService: FeatureToggleService
	) {
	}

	ngOnInit() {
        let userPreference = JSON.parse(localStorage.getItem('user_preference'));
        this.userTempPreference = userPreference.temperatureUnit;
		this.isVofm = this.authService.isUserViewOnly();
		this.subscriptions['vacationAddedSubscriptions'] = this.pubNubService.vacationAdded.subscribe({
			next: (addedVacId) => {
				this.UpdateBuildingsDataForVacationEdit(addedVacId);
			}
		});
		this.humdityValues = new Array(101).fill(0).map((x, i) => i);
		this.helperService.clearData();
		this.defaultMinHumidity = 0;
		this.defaultMaxHumidity = 0;
		this.defaultCompensateHumidity = 0;
		this.defaultDemandResponse = 0;
		this.loaderService.active(true);
		this.skeletonContentLoaded = false
		this.dataRefetchOnRefresh();
		this.ccuAlertService.refreshAlertCountEventTrigger();
		this.helperCancelSubscription = this.helperService.cancelledChange.subscribe((param: any) => {
			this.revertChangedValues(param.list);
		});
		this.subscriptions['flagChange'] = this.featureToggleService.flagChange.subscribe((flags)=>{
			this.setFlags(flags);
		  })
	  
		this.useFeatureToggles();
	}

	ngAfterViewInit(){
        let loadTime = Date.now() - this.analyticsService.getPageLoadStartTime();
        this.analyticsService.pageLoadTime(loadTime);
    }


	UpdateBuildingsDataForVacationEdit(entityid: any) {
		let buildings: any = {
			buildings: {},
			floors: {},
			floorsMaster: [],
			ccus: {},
			floorCcu: {},
			rooms: {},
			equips: {},
			zones: {},
			schedule: [],
		};

		this.siteService.findByQuery(this.query + this.siteRef).pipe(
			map(this.helperService.listEntities),
			map(this.helperService.createEntityStructure),
		).subscribe(
			(response: any) => {
				this.buildingMaster = response;
				this.buildings['entites'] = ArrayUtil.deepFlatten(response, 'entities');
				buildings["floors"] = this.helperService.getBuildingComponents(this.buildingMaster, 'floor');
				let roomVacationUpdated: any = undefined;
				buildings["floors"].map((floor, roomindex) => {
					floor.entities.map((ent) => {
						if (ent.type == "room") {
							let vacationids = this.helperService.getPointIdbyTags(buildings, ['schedule', 'range', 'vacation'], ent._id);
							if (vacationids.includes(entityid)) {
								roomVacationUpdated = ent._id;
								this.buildings.floors[roomindex].entities = buildings.floors[roomindex].entities;

							}

						}
					})
				});
				this.getScheduleInfoForCCU();
			},
		);
	}
	/**
		*  Here this method return the labels based on the value input
		*/
	displayLabel(value) {
		switch (value) {
			case 5:
				return 'Full comfort'
			case 4:
				return 'Partial comfort '
			case 3:
				return 'Partial economy'
			case 2:
				return 'Full economy'
		}
	}
	/**
		*  Here this method fetches the data on reload
		*/
	async dataRefetchOnRefresh() {
		let buildingDetails = JSON.parse(localStorage.getItem('SelectedSite'));
		this.selectedBuilding.siteId = buildingDetails.siteId;
		this.siteRef = this.helperService.stripHaystackTypeMapping(buildingDetails.siteId);
		this.getEntitySubscription =
			this.siteService.findByQuery(this.query + this.siteRef).pipe(
				map(this.helperService.listEntities),
				map(this.helperService.createEntityStructure),
			).subscribe(
				(response: any) => {
					this.buildingMaster = response;
					const entities = ArrayUtil.deepFlatten(response, 'entities');
					const allZones = this.getNodes(entities, ['room']);
					this.buildings['entites'] = ArrayUtil.deepFlatten(response, 'entities');
					this.buildings.zones = allZones;
					this.buildings.buildings = [];
					this.buildings.schedule = this.helperService.getBuildingComponents(this.buildingMaster, 'schedule');
					this.buildings.floors = this.helperService.getBuildingComponents(this.buildingMaster, 'floor');
					this.buildings.ccus = this.helperService.getBuildingComponents(this.buildingMaster, 'ccu');
					this.buildings.equips = this.helperService.getBuildingComponents(this.buildingMaster, 'equip');
					this.buildings.buildingOccupancy = this.helperService.getBuildingComponents(this.buildingMaster, 'buildingoccupancy') || [];
					this.selectedBuilding.ccus = this.buildings.ccus;
					this.ccuId = this.selectedBuilding?.ccus[0]?._id;
					this.selectedBuildingService.setBuildingData(this.selectedBuilding);
					this.pubNubService.subscribe([this.siteRef]);
					this.getCcus();
					this.vacationsService.getSIteTz(this.siteRef);
					this.vacationsService.siteTimezone.subscribe(tz => this.siteTz = tz);
				},
			);
	}
	/**
		*  Here this method checks if rhe oaoPaired check
		*/
	checkIfOAOIsPaired() {
		const equipObj = this.getEquip(['system', 'equip', 'profile']);
        const hasDomainName = equipObj?.markers?.includes('domainName');
		this.siteService.oaoPairedCheck(this.ahuRef,'(oao and equip and not zone)').subscribe(res => {
			if (res?.rows?.length > 0) {
				this.OaoShow = true;
				let systemPoints
				let oaoRef = this.helperService.stripHaystackTypeMapping(res.rows[0].id);

				if ((this.checkForVavDabSystemExternalProfiles.indexOf(this.systemReconfigProfileName) > -1) ||
					(this.checkForAdvanceAhuProfiles.indexOf(this.systemReconfigProfileName) > -1) ||
					(hasDomainName)) {
					systemPoints = [
						{
							pointName: 'oaoLastUpdated',
							query: `(equipRef==@${oaoRef})`,
							type: 'read',
							tags: ['heartbeat', 'oao'],
							domainName: 'heartBeat'
						},
						{
							pointName: 'oao',
							query: `(equipRef==@${oaoRef.split(' ')[0]})`,
							type: 'read',
							tags: ['return', 'air', 'co2', 'sensor'],
							domainName: 'returnAirCo2'
						},
					    {
							pointName: 'smartPrepurge',
							type: 'write',
							domainName: 'systemPrePurgeEnable'
						},
						{
							pointName: 'smartPostpurge',
							type: 'write',
							domainName: 'systemPostPurgeEnable'
						},
						{
							pointName: 'enhancedVentilation',
							type: 'write',
							domainName: 'systemEnhancedVentilationEnable'
						}
					];
				} else {
					 systemPoints = [
						{
							pointName: 'oaoLastUpdated',
							query: `(equipRef==@${oaoRef})`,
							type: 'read',
							tags: ['heartbeat']
						},
						{
							pointName: 'oao',
							type: 'read',
							tags: ['return', 'air', 'co2', 'sensor']
						},
						{
							pointName: 'smartPrepurge',
							type: 'write',
							tags: ['system', 'userIntent', 'prePurge', 'enabled']
						},
						{
							pointName: 'smartPostpurge',
							type: 'write',
							tags: ['system', 'userIntent', 'postPurge', 'enabled']
						},
						{
							pointName: 'enhancedVentilation',
							type: 'write',
							tags: ['system', 'userIntent', 'enhanced', 'ventilation']
						}
					];
				}
				this.systemPoints = this.systemPoints.concat(systemPoints);
			}
			else {
				this.OaoShow = false;
			}
			this.getCCUSettingData();
		}, err => {
			this.getCCUSettingData();
		});
	}


	checkIfConnectModuleIsPaired() {
		this.siteService.oaoPairedCheck(this.ahuRef, 'equip and connectModule').subscribe(res => {
			if (res && res?.rows && res?.rows?.length > 0) {
				let connectModuleId = this.helperService.stripHaystackTypeMapping(res?.rows[0]?.id);
				let systemPoints = [];
				if (this.checkForAdvanceAhuProfiles.indexOf(this.systemReconfigProfileName) > -1) {
					systemPoints = [
                        {
                            pointName: 'connectmodulecoolingstage1',
                            query: `(equipRef==@${connectModuleId})`,
                            type: 'read',
                            domainName: "loadCoolingStage1"
                        }, {
                            pointName: 'connectmodulecoolingstage2',
                            query: `(equipRef==@${connectModuleId})`,
                            type: 'read',
                            domainName: "loadCoolingStage2"
                        }, {
                            pointName: 'connectmodulecoolingstage3',
                            query: `(equipRef==@${connectModuleId})`,
                            type: 'read',
                            domainName: "loadCoolingStage3"
                        }, {
                            pointName: 'connectmodulecoolingstage4',
                            query: `(equipRef==@${connectModuleId})`,
                            type: 'read',
                            domainName: "loadCoolingStage4"
                        }, {
                            pointName: 'connectmodulecoolingstage5',
                            query: `(equipRef==@${connectModuleId})`,
                            type: 'read',
                            domainName: "loadCoolingStage5"
                        },
                        {
                            pointName: 'connectmoduleheatingstage1',
                            query: `(equipRef==@${connectModuleId})`,
                            type: 'read',
                            domainName: "loadHeatingStage1"
                        }, {
                            pointName: 'connectmoduleheatingstage2',
                            query: `(equipRef==@${connectModuleId})`,
                            type: 'read',
                            domainName: "loadHeatingStage2"
                        }, {
                            pointName: 'connectmoduleheatingstage3',
                            query: `(equipRef==@${connectModuleId})`,
                            type: 'read',
                            domainName: "loadHeatingStage3"
                        }, {
                            pointName: 'connectmoduleheatingstage4',
                            query: `(equipRef==@${connectModuleId})`,
                            type: 'read',
                            domainName: "loadHeatingStage4"
                        }, {
                            pointName: 'connectmoduleheatingstage5',
                            query: `(equipRef==@${connectModuleId})`,
                            type: 'read',
                            domainName: "loadHeatingStage5"
                        }, {
                            pointName: 'connectmoduleloadbasedHeatingcontrol',
                            query: `(equipRef==@${connectModuleId})`,
                            type: 'read',
                            domainName: "loadBasedHeatingControl"
                        }, {
                            pointName: 'connectmoduleloadbasedcoolingcontrol',
                            query: `(equipRef==@${connectModuleId})`,
                            type: 'read',
                            domainName: "loadBasedCoolingControl"
                        },
                    ];
				}

				this.systemPoints = (this.systemPoints || []).concat(systemPoints);
			}
			this.getCCUSettingData();
		}, (err) => {
			this.getCCUSettingData();
		});
	}
	/**
		*  Here this method returns the date in the prescribed format
		*/
	dateFormat(val) {
		return DateUtil.dateFormat(val, this.siteService.getIANATimeZone(this.siteTz));
	}
	/**
		*  Here this method fetches all the co2 ccus
		*/
	fetchCO2AllCCu() {
		this.cO2AllCCu = {};
		let concatQuery = '';
		this.siteCcus?.forEach((ccuObj, i) => {
			concatQuery += 'ahuRef==@' + ccuObj?.ahuRef + `${this.buildings?.ccus?.length - 1 == i ? '' : ' or '}`
		});
		concatQuery = '(' + concatQuery + ') and ((oao and equip and not zone)) and siteRef==@' + this.siteRef;
		this.siteService.findByQuery(concatQuery).pipe(takeUntil(this.unsubscribe)).subscribe(res => {
			if (res?.rows?.length > 0) {
				res = this.helperService.stripHaystackTypeMapping(res);
				let query = '';
				res?.rows?.forEach((oaoEquip, i) => {
					query += 'equipRef==@' + oaoEquip.id + `${res.rows.length - 1 == i ? '' : ' or '}`
				});
				query = '(' + query + ') and (return and air and co2 and sensor) and siteRef==@' + this.siteRef;
				
				this.siteService.findByQuery(query).pipe(takeUntil(this.unsubscribe)).subscribe(({ rows }) => {
				if (rows?.length > 0) {
					rows = this.helperService.stripHaystackTypeMapping(rows);
					this.siteService.getCurrenthisReadMany(rows?.map(p=>p?.id)).pipe(
					map(this.helperService.stripHaystackTypeMapping),  takeUntil(this.unsubscribe))
					.subscribe(({ rows }) => {
						if (rows?.length > 0) {
							rows = this.helperService.stripHaystackTypeMapping(rows);
							rows.forEach((row) => {
								this.cO2AllCCu[row.ccuRef] = ObjectUtil.isNotUndefinedOrNull(row?.data?.[0]?.val) ? row.data[0].val : 'N/A';
							});
						}
					});
				}
				
				});
			}
		}
		);
	}
	/**
		*  Here this method assigns all the ccus to the siteCcu property
		*/
	getCcus() {
		this.getCcuSubscription =
			this.siteService.getCcus(this.siteRef).subscribe(({ rows }) => {
				this.siteCcus = this.helperService.stripHaystackTypeMapping(rows);
				if (rows.length == 0) {
					this.showEquipPaired = true
				}
				this.fetchAverageHumidity();
				this.fetchCO2AllCCu();
				this.addHighlightClass(this.ccuId);
				this.loaderService.active(false);
				this.skeletonContentLoaded = true
			});
	}
	/**
		*  Here this method fetches the average humidity
		*/
	fetchAverageHumidity() {
		this.averageHumidity = {};
		let concatQuery = '';
		this.siteCcus?.forEach((ccuObj, i) => {
			concatQuery += 'equipRef==@' + ccuObj?.ahuRef + `${this.buildings?.ccus?.length - 1 == i ? '' : ' or '}`
		});
		concatQuery = '(' + concatQuery + ') and (average and humidity) and siteRef==@' + this.siteRef;
		this.siteService.findByQuery(concatQuery).pipe( takeUntil(this.unsubscribe)).subscribe(({ rows }) => {
			if (rows?.length > 0) {
				rows = this.helperService.stripHaystackTypeMapping(rows);
				this.siteService.getCurrenthisReadMany(rows.map(p=>p.id)).pipe(
					map(this.helperService.stripHaystackTypeMapping),  takeUntil(this.unsubscribe))
					.subscribe(({ rows }) => {
						if (rows?.length > 0) {
							rows = this.helperService.stripHaystackTypeMapping(rows);
							rows.forEach((row) => {
								this.averageHumidity[row.ccuRef] = ObjectUtil.isNotUndefinedOrNull(row?.data?.[0]?.val) ? row.data[0].val : 'N/A';
							});
						}
					});
			}
		});
		


	}
	/**
		*  Here this method retruns the user setting data
		*/
	getUserSettingData() {
		this.getPointDataSubscription =
			this.helperService.getPointData().subscribe(res => {
				console.timeEnd('getEntitiesInBuilding');
				this.ccuSetting = (res) ? ObjectUtil.deepClone(res) : this.ccuSetting;
				if (this.ccuSetting['desiredComfortIndex'] && this.ccuSetting['desiredComfortIndex'].val) {
					this.desiredCIValue = this.ccuSetting['desiredComfortIndex'].val;
				}

				if (this.ccuSetting['occStatus']) {
					let occstatus = this.ccuSetting['occStatus'].val;
					this.OccStatus = this.occStatusValues[occstatus];
				}

				if (this.ccuSetting['oao'] && this.ccuSetting['oao'].val) {
					this.oaoArcValue = parseInt(this.ccuSetting.oao.val);
				}

				if (this.ccuSetting['cloudLastUpdated'] && this.ccuSetting['cloudLastUpdated'].hasOwnProperty('val')) {
					this.ccuSetting['cloudLastUpdated']['val'] = dayjs.utc(this.ccuSetting['cloudLastUpdated']['time']).local();
				}

				this.desiredCISliderValue = this.rangeSliderHack[this.desiredCIValue];
				if (this.checkForAdvanceAhuProfiles.indexOf(this.systemReconfigProfileName) > -1) {
					this.ahuProfilesGlobalisationCheck();
				}
				this.isOnline('btu');
				this.isOnline('emr');
				this.isOAOOnline();
				this.setIntrinsicScheduleData(res);
			})
	}
	/**
		*  Here this method disables all the inputs
		*/
	disableAllInputs() {
		document.querySelectorAll('input').forEach(element => {
			element.disabled = true;
		});
		this.onSystemMode = true;

	}
	/**
		*  Here this method enables all the inputs
		*/
	enableAllInputs() {
		document.querySelectorAll('input').forEach(element => {
			element.disabled = false;
		});
		this.onSystemMode = false;
	}


	/**
		*  Here this method adds the highlighted class and enables or disables input based on systemProfileName
		*/
		addHighlightClass(ccuId) {
			this.siteCcus.forEach(ccu => {
				if (ccu.id.search(ccuId) !== -1) {
					ccu.active = true;
					this.ahuRef = this.helperService.stripHaystackTypeMapping(ccu.ahuRef);
					this.ccuEquipRef = this.helperService.stripHaystackTypeMapping(ccu.equipRef);
					this.deviceHelperService.getCCUSystemProfile(this.siteRef, this.ahuRef);
					let subs = this.deviceHelperService.systemProfileSubject.subscribe(
						res => {
							if (res.systemProfileName == 'SYSTEM_DEFAULT') {
								this.disableAllInputs();
							} else {
								this.enableAllInputs();
							}
							this.systemReconfigProfileName = res.systemProfileName;
		
							// Place the methods here to ensure they are called after a successful subscription
							
							this.getSystemProfileType();
							this.fetchCCuListWithPairedZones(ccu);
							this.getCcuSettings();
							setTimeout(() => {
								this.checkIfOAOIsPaired();
								/**
									 * Note this is specially for vavAdvancedHybridAhuV2 that to connect module is paired with equip.
									 * As of now, without load and sat based, we cannot configure the connect module. In the future, 
									 * there are chances to configure the connect module directly.
									 * Handling in the different method this method is used only for conditioning mode check.
									 * As of the current implementation, we are not implementing connect module in widgets and equip graphics.
									 * In the future, we can implement the connect module in widgets and equip graphics based on requirements.
									 * Sat based will not come in the connect module.
									 */
								if (this.checkForAdvanceAhuProfiles.indexOf(this.systemReconfigProfileName) > -1) {
									this.checkIfConnectModuleIsPaired();
								}
							}, 1000);
							if(subs) {
								subs.unsubscribe();
							}
						},
						error => {
							// Handle any errors here
							console.error("Error in systemProfileSubject subscription:", error);
							// You can also set a flag or call a method to handle the error case
						}
					);
				} else {
					ccu.active = false;
				}
			});
		}
	/**
		*  Here this method checks if oao is online
		*/
	isOAOOnline() {
		if (!(this.ccuSetting['oaoLastUpdated'] && this.ccuSetting['oaoLastUpdated'].hasOwnProperty('val'))) return;
		this.ccuSetting['oaoLastUpdated']['val'] = dayjs.utc(this.ccuSetting['oaoLastUpdated']['time']).local();
		let timeDiffMinutes = Math.abs(this.ccuSetting['oaoLastUpdated']['val'].diff(dayjs(), 'minutes'))
		return timeDiffMinutes < 16;
	}
	/**
		*  Here this method returns true if timeDiffMinutes < 16
		*/
	isOnline(key) {
		if (!(this.ccuSetting[key] && this.ccuSetting[key]['lastUpdated'] && this.ccuSetting[key]['lastUpdated'].hasOwnProperty('val'))) return;
		this.ccuSetting[key]['lastUpdated']['val'] = dayjs.utc(this.ccuSetting[key]['lastUpdated']['time']).local();
		let timeDiffMinutes = Math.abs(this.ccuSetting[key]['lastUpdated']['val'].diff(dayjs(), 'minutes'))
		return timeDiffMinutes < 16;
	}
	/**
		*  Here this method calls getCCUSettingData() method based on systemModePointId
		*/
	getCcuSettings() {
		//NOTE: For conditioning mode , due to tag change adding a work around for backword compatibility , in future if all builds
		//of CCU >=523 then we can use the updated tags , till then the check will be in place
		let equipObj = this.getEquip(['system', 'equip', 'profile']);
		let systemMode = equipObj ? this.helperService.getPointIdbyTags(equipObj, ['sp', 'system', 'mode', 'rtu'])[0] : null;
		const systemPoints = [
			{
				pointName: 'systemMode',
				query: `(equipRef==@${this.ahuRef})`,
				type: 'write',
				tags: ['system', 'userIntent', 'mode', `${!systemMode ? 'conditioning' : 'rtu'}`],
				domainName: "conditioningMode"
			},
			{
				pointName: 'cloudLastUpdated',
				query: `(equipRef==@${this.ccuEquipRef})`,
				type: 'read',
				tags: ['diag', 'cloud', 'connected']
			},
			{
				pointName: 'desiredComfortIndex',
				type: 'write',
				tags: ['system', 'userIntent', 'ci', 'desired']
			},
			{
				pointName: 'demandResponse',
				type: 'write',
				tags: ['userIntent', 'demand', 'system', 'response']
			},
			{
				type: 'write',
				pointName: 'minHumidity',
				tags: ['min', 'system', 'target', 'humidity'],
				domainName: "systemtargetMinInsideHumidity"
			},
			{
				tags: ['target', 'humidity', 'max', 'system'],
				pointName: 'maxHumidity',
				type: 'write',
				domainName: "systemtargetMaxInsideHumidity"
			},
			{
				pointName: 'equipStatus',
				type: 'write',
				tags: ['system', 'status', 'message'],
				domainName: "equipStatusMessage"
			},
			{
				pointName: 'occupancyStatus',
				type: 'write',
				tags: ['system', 'scheduleStatus'],
				domainName: "equipScheduleStatus"
			},
			{
				pointName: 'occStatus',
				type: 'read',
				tags: ['occStatus']
			},
			{
				pointName: 'ductStaticPressureSetPoint',
				type: 'read',
				domainName: "ductStaticPressureSetpoint"
			}, {
				pointName: 'supplyAirflowTempSetpoint',
				type: 'read',
				domainName: "supplyAirflowTemperatureSetpoint"
			}, {
				pointName: 'dcvDamper',
				type: 'read',
				domainName: 'dcvDamperCalculatedSetpoint'
			}, {
                pointName: 'dualSetpointControlEnable',
                type: 'write',
                domainName: 'dualSetpointControlEnable'
            }, {
                pointName: 'airTempHeatingSp',
                type: 'read',
                domainName: 'airTempHeatingSp'
            }, {
                pointName: 'airTempCoolingSp',
                type: 'read',
                domainName: 'airTempCoolingSp'
            }, {
                pointName: 'dualSetpointControlEnable',
                type: 'write',
                domainName: 'dualSetpointControlEnable'
            }, {
                pointName: 'operatingMode',
                type: 'write',
                domainName: 'operatingMode'
            },{
                pointName: 'demandResponseActivation',
                type: 'write',
                tags: [ 'demand', 'activation', 'response']
            },{
                pointName: 'relay-1',
                type: 'write',
                tags: ['system', 'point' , 'config', 'enabled', 'output', 'sp', 'relay1'],
                domainName: 'relay1OutputEnable',
            },{
                pointName: 'relay-2',
                type: 'write',
                tags: ['system', 'point' , 'config', 'enabled', 'output', 'sp', 'relay2'],
                domainName: 'relay2OutputEnable',
            },
            {
                pointName: 'relay-3',
                type: 'write',
                tags: ['system', 'point' , 'config', 'enabled', 'output', 'sp', 'relay3'],
                domainName: 'relay3OutputEnable',
            },
            {
                pointName: 'relay-4',
                type: 'write',
                tags: ['system', 'point' , 'config', 'enabled', 'output', 'sp', 'relay4'],
                domainName: 'relay4OutputEnable',
            },
            {
                pointName: 'relay-5',
                type: 'write',
                tags: ['system', 'point' , 'config', 'enabled', 'output', 'sp', 'relay5'],
                domainName: 'relay5OutputEnable',
            },
            {
                pointName: 'relay-6',
                type: 'write',
                tags: ['system', 'point' , 'config', 'enabled', 'output', 'sp', 'relay6'],
                domainName: 'relay6OutputEnable',
            },
            {
                pointName: 'relay-7',
                type: 'write',
                tags: ['system', 'point' , 'config', 'enabled', 'output', 'sp', 'relay7'],
                domainName: 'relay7OutputEnable',
            },
            {
                pointName: 'analog-out1',
                type: 'write',
                tags: ['system', 'point' , 'config', 'enabled', 'output', 'sp', 'analog1'],
				domainName: 'analog1OutputEnable'
            },
            {
                pointName: 'analog-out2',
                type: 'write',
                tags: ['system', 'point' , 'config', 'enabled', 'output', 'sp', 'analog2'],
				domainName: 'analog2OutputEnable'
            },
            {
                pointName: 'analog-out3',
                type: 'write',
                tags: ['system', 'point' , 'config', 'enabled', 'output', 'sp', 'analog3'],
				domainName: 'analog3OutputEnable'
            },
            {
                pointName: 'analog-out4',
                type: 'write',
                tags: ['system', 'point' , 'config', 'enabled', 'output', 'sp', 'analog4'],
				domainName: 'analog4OutputEnable'
            },
            {
                pointName: 'dcwbEnabled',
                type: 'write',
                tags: ['dcwb', 'enabled'],
				filterTags:['adaptive','maximized','analog4']
            },
            {
                pointName: 'relay-1Mapping',
                type: 'write',
                tags: ['system', 'point', 'config',  'association', 'output', 'sp' , 'relay1'],
                domainName: 'relay1OutputAssociation',
            },
            {
                pointName: 'relay-2Mapping',
                type: 'write',
                tags: ['system', 'point', 'config',  'association', 'output', 'sp' , 'relay2'],
                domainName: 'relay2OutputAssociation',
            },
            {
                pointName: 'relay-3Mapping',
                type: 'write',
                tags: ['system', 'point', 'config',  'association', 'output', 'sp' , 'relay3'],
                domainName: 'relay3OutputAssociation',
            },
            {
                pointName: 'relay-4Mapping',
                type: 'write',
                tags: ['system', 'point', 'config',  'association', 'output', 'sp' , 'relay4'],
                domainName: 'relay4OutputAssociation',
            },
            {
                pointName: 'relay-5Mapping',
                type: 'write',
                tags: ['system', 'point', 'config',  'association', 'output', 'sp' , 'relay5'],
                domainName: 'relay5OutputAssociation',
            },
            {
                pointName: 'relay-6Mapping',
                type: 'write',
                tags: ['system', 'point', 'config',  'association', 'output', 'sp' , 'relay6'],
                domainName: 'relay6OutputAssociation',
            },
            {
                pointName: 'relay-7Mapping',
                type: 'write',
                tags: ['system', 'point', 'config',  'association', 'output', 'sp' , 'relay7'],
                domainName: 'relay7OutputAssociation',
            },
            {
                pointName: 'daikin-heating',
                type: 'write',
                tags: ['system', 'config', 'heating', 'enabled']
            },
            {
                pointName: 'daikin-cooling',
                type: 'write',
                tags: ['system', 'config', 'cooling', 'enabled']
            },
            {
                pointName: 'relay-7Mapping',
                type: 'write',
                tags: ['system', 'point', 'config', 'humidifier', 'sp', 'relay7']
            },
            //VAV Advanced AHU Points
            {
                pointName: 'supplyAirflowTemperatureSetpoint',
                type: 'read',
                domainName: 'supplyAirflowTemperatureSetpoint'

            }, {
                pointName: 'ductStaticPressureSetpoint',
                type: 'read',
                domainName: 'ductStaticPressureSetpoint'
            },
            {
                pointName: 'supplyAirflowTempControl',
                type: 'write',
                domainName: 'supplyAirTempControlOn'
            }, {
                pointName: 'pressureBasedFanControl',
                type: 'write',
                domainName: 'pressureBasedFanControlOn'
            },
            //Supply Air Temp Points 
            {
                pointName: 'supplyAirTemperature1',
                type: 'read',
                domainName: 'supplyAirTemperature1'
            }, {
                pointName: 'supplyAirTemperature2',
                type: 'read',
                domainName: 'supplyAirTemperature2'
            }, {
                pointName: 'supplyAirTemperature3',
                type: 'read',
                domainName: 'supplyAirTemperature3'
            }, {
                pointName: 'averageSat',
                type: 'read',
                domainName: 'averageSat'
            }, {
                pointName: 'satSpMin',
                type: 'read',
                domainName: 'minSat'
            }, {
                pointName: 'satSpMax',
                type: 'read',
                domainName: 'maxSat'
            },
            //Duct static pressure points
            {
                pointName: 'ductStaticPressureSensor1',
                type: 'read',
                domainName: 'ductStaticPressureSensor1_2'
            },
            {
                pointName: 'ductStaticPressureSensor2',
                type: 'read',
                domainName: 'ductStaticPressureSensor2_2'
            }, {
                pointName: 'ductStaticPressureSensor3',
                type: 'read',
                domainName: 'ductStaticPressureSensor3_2'
            }, {
                pointName: 'averagePressure',
                type: 'read',
                domainName: 'averagePressure'
            }, {
                pointName: 'minPressure',
                type: 'read',
                domainName: 'minPressure'
            }, {
                pointName: 'maxPressure',
                type: 'read',
                domainName: 'maxPressure'
            }, {
                pointName: "coolingstage1",
                domainName: "loadCoolingStage1",
                type: "read",
            }, {
                pointName: "coolingstage2",
                domainName: "loadCoolingStage2",
                type: "read",
            }, {
                pointName: "coolingstage3",
                domainName: "loadCoolingStage3",
                type: "read",
            }, {
                pointName: "coolingstage4",
                domainName: "loadCoolingStage4",
                type: "read",
            }, {
                pointName: "coolingstage5",
                domainName: "loadCoolingStage5",
                type: "read",
            }, {
                pointName: "heatingstage1",
                domainName: "loadHeatingStage1",
                type: "read",
            }, {
                pointName: "heatingstage2",
                domainName: "loadHeatingStage2",
                type: "read",
            }, {
                pointName: "heatingstage3",
                domainName: "loadHeatingStage3",
                type: "read",
            }, {
                pointName: "heatingstage4",
                domainName: "loadHeatingStage4",
                type: "read",
            }, {
                pointName: "heatingstage5",
                domainName: "loadHeatingStage5",
                type: "read",
            },
			{
                pointName: "satcoolingstage1",
                domainName: "satCoolingStage1Feedback",
                type: "read",
            }, {
                pointName: "satcoolingstage2",
                domainName: "satCoolingStage2Feedback",
                type: "read",
            }, {
                pointName: "satcoolingstage3",
                domainName: "satCoolingStage3Feedback",
                type: "read",
            }, {
                pointName: "satcoolingstage4",
                domainName: "satCoolingStage4Feedback",
                type: "read",
            }, {
                pointName: "satcoolingstage5",
                domainName: "satCoolingStage5Feedback",
                type: "read",
            }, {
                pointName: "satheatingstage1",
                domainName: "satHeatingStage1Feedback",
                type: "read",
            }, {
                pointName: "satheatingstage2",
                domainName: "satHeatingStage2Feedback",
                type: "read",
            }, {
                pointName: "satheatingstage3",
                domainName: "satHeatingStage3Feedback",
                type: "read",
            }, {
                pointName: "satheatingstage4",
                domainName: "satHeatingStage4Feedback",
                type: "read",
            }, {
                pointName: "satheatingstage5",
                domainName: "satHeatingStage5Feedback",
                type: "read",
            },
			{
                pointName: "loadbasedcoolingcontrol",
                domainName: "loadBasedCoolingControl",
                type: "read",
            },{
                pointName: "satbasedcoolingcontrol",
                domainName: "satBasedCoolingControlFeedback",
                type: "read",
            },{
                pointName: "satbasedheatingcontrol",
                domainName: "satBasedHeatingControlFeedback",
                type: "read",
            },{
                pointName: "loadbasedheatingcontrol",
                domainName: "loadBasedHeatingControl",
                type: "read",
            },{
				pointName: 'advancedAhuOperatingMode',
				type: 'read',
				domainName: 'operatingMode'
			},
			{
                pointName: 'dcvDamperControl',
                type: 'read',
                domainName: ['co2BasedDamperControl']
            },{
				pointName: 'compositSignal',
				type: 'read',
				domainName: 'compositeSignal'
			},

			//Duct static pressure Points New 
			{
				pointName: 'ductStaticPressureSensor1_1',
				type: 'read',
				domainName: 'ductStaticPressureSensor1_1',
			}, {
				pointName: 'ductStaticPressureSensor1_2',
				type: 'read',
				domainName: 'ductStaticPressureSensor1_2',
			}, {
				pointName: 'ductStaticPressureSensor1_10',
				type: 'read',
				domainName: 'ductStaticPressureSensor1_10',
			},
			//
			{
				pointName: 'ductStaticPressureSensor2_1',
				type: 'read',
				domainName: 'ductStaticPressureSensor2_1',
			}, {
				pointName: 'ductStaticPressureSensor2_2',
				type: 'read',
				domainName: 'ductStaticPressureSensor2_2',
			}, {
				pointName: 'ductStaticPressureSensor2_10',
				type: 'read',
				domainName: 'ductStaticPressureSensor2_10',
			},
			//
			{
				pointName: 'ductStaticPressureSensor3_1',
				type: 'read',
				domainName: 'ductStaticPressureSensor3_1',
			}, {
				pointName: 'ductStaticPressureSensor3_2',
				type: 'read',
				domainName: 'ductStaticPressureSensor3_2',
			}, {
				pointName: 'ductStaticPressureSensor3_10',
				type: 'read',
				domainName: 'ductStaticPressureSensor3_10',
			},
			// 
			{
				pointName: 'pressureSensorBusAdd0',
				type: 'write',
				domainName: 'pressureSensorBusAdd0',
			}, {
				pointName: 'analog1InputAssociation',
				type: 'write',
				domainName: 'analog1InputAssociation',
			}, {
				pointName: 'analog2InputAssociation',
				type: 'write',
				domainName: 'analog2InputAssociation',
			},
		];
		this.systemPoints = systemPoints;
	}

	checkIfSystemBTUIsPaired(sysBtuData) {
		let btuSystemData;
		this.siteService.findByQuery(`equipRef==@${sysBtuData[0]?.id}`).subscribe(sysBtuResponse => {
			btuSystemData = sysBtuResponse?.rows;
			this.fetchSystemModbusData(btuSystemData, 'btu', sysBtuData);
		}, error => {
			console.error("Error in ccusResponseObservable:", error);
		});
	}

	checkIfSystemEMRIsPaired(sysEmrData) {
		let emrSystemData;
		this.siteService.findByQuery(`equipRef==@${sysEmrData[0]?.id}`).subscribe(sysEmrResponse => {
			emrSystemData = sysEmrResponse?.rows;
			this.fetchSystemModbusData(emrSystemData, 'emr', sysEmrData);
		}, error => {
			console.error("Error in :", error);
		});
	}

	      //this method will fetch the system modbus data
	fetchSystemModbusData(systemData, modbusTypeCheck, res) {
		let isHeartBeat = false;
		if (systemData?.length > 0) {

			if (modbusTypeCheck === 'btu') {
				this.showBtu = true;
			}

			if (modbusTypeCheck === 'emr') {
				this.showEmr = true;
			}


			if (modbusTypeCheck === 'modbus') {
				this.showModbus = true;
			}

			// Note: Even though we are fetching data on an equipment basis, we will receive data specific to that particular equipment.
			// As per the requirement, we need to display points with the 'displayInUi' tag, hence the following filter is necessary.
			// Sometimes, we will receive schedule type and other points where the 'displayInUi' key may not exist.
			// In such cases, if we do not use the filter, it will break the code.

			let points = systemData.filter(p => p['displayInUi'] && p['equipRef']);
			const heartBeatPoints = systemData.filter(p => p['heartbeat'] && p['equipRef']) || [];
			isHeartBeat = heartBeatPoints.length > 0;
			points = heartBeatPoints.concat(points);

			const modbusProfileType = res[0].profile.split('_');
			const splitModbusDis = res[0]?.['dis']?.split('-');
			const modbusFromCCU = splitModbusDis[splitModbusDis?.length - 2]?.toLowerCase() == res[0]?.equipType?.toLowerCase();
			
			let modbusEquipName;
			
			// Condition to handle displayName from response depending on if it is from hayloft or not.
			if (modbusFromCCU) {
				const buildingDetails = JSON.parse(localStorage.getItem('SelectedSite'));
				const modbusProfile = res[0]?.['dis']?.substring(buildingDetails?.siteName?.length + 1).slice(0, -(res[0]?.group?.length + 1) - (modbusProfileType[1]?.length + 1));
				
                //format of value is not required for ahu profiles, as will be consuming the name from ccu directly.
                modbusEquipName = modbusTypeCheck === 'modbus' && ( this.systemReconfigProfileName == 'vavExternalAHUController' || this.systemReconfigProfileName == 'dabExternalAHUController') ?  `${res[0]?.['dis']?.substring(buildingDetails?.siteName?.length + 1)?.split('-')[0]}` +'-'+res[0]?.equipType+'(' +`${res[0]?.equipType?.toUpperCase()}` + `${res[0]?.group}` + ')' :  `${modbusProfile}-${modbusProfileType[1]?.toLowerCase()}` + '(' + `${modbusProfileType[1]}` + `${res[0]?.group}` + ')';
			} else {
			  modbusEquipName = `${splitModbusDis[splitModbusDis?.length - 2]}` + '(' + `${res[0]?.group}` + ')';
			}
			
			if (modbusTypeCheck === 'btu') {
				this.btuMeterType = modbusEquipName;
				this.hideDisplayUIBtuText = isHeartBeat ? points.length > 1 : true;
			}
			
			if (modbusTypeCheck === 'emr') {
				this.emrMeterType = modbusEquipName;
				this.hideDisplayUIText = isHeartBeat ? points.length > 1 : true;
			}
			
			if (modbusTypeCheck === 'modbus') {
				this.modbusType = modbusEquipName;
				this.hideDisplayUIModbusText = isHeartBeat ? points.length > 1 : true;
			}

			if (points.length > 0) {
				for (let i = 0; i < points.length; i++) {
					const label = (i === 0 && isHeartBeat) ? 'lastUpdated' : `modbus${i + 1}`;
					this.getSystemModbusSettings(
						label,
						[this.helperService.stripHaystackTypeMapping(points[i].id)],
						points[i]['writable'] ? 'read&hisWrite' : 'read&hisRead',
						modbusTypeCheck
					);
				}
			} else {
				if (modbusTypeCheck === 'btu') {
					this.hideDisplayUIBtuText = false;
					this.getUserSettingData();
				}

				if (modbusTypeCheck === 'emr') {
					this.hideDisplayUIText = false;
					this.getUserSettingData();
				}

				if (modbusTypeCheck === 'modbus') {
					this.hideDisplayUIModbusText = false;
					this.getUserSettingData();
				}
			}
		}
	}


	checkIfSystemModbusIsPaired(sysModbusData) {
		let modbusSystemData;
		this.siteService.findByQuery(`equipRef==@${sysModbusData[0]?.id}`).subscribe(sysModbusResponse => {
			modbusSystemData = sysModbusResponse?.rows;
			this.fetchSystemModbusData(modbusSystemData, 'modbus', sysModbusData);
			// This method is for fetching the user intent only for VAV/DAB EXTERNAL AHU
			this.modbusEquipId = sysModbusData[0].id;
			this.checkForModbusCurrentValues(this.modbusEquipId);
		}, error => {
			console.error("Error in :", error);
		});
	}


	checkForModbusCurrentValues(modbusEquipId) {
        const systemPoints = [
            {
                pointName: 'ductStaticPressureCurrent',
                query: `(equipRef==@${modbusEquipId})`,
                type: 'read',
                tags: ['discharge', 'air', 'pressure', 'sensor','displayInUi','modbus','system'],
              },  {
                pointName: 'supplyAirflowTempCurrent',
                query: `(equipRef==@${modbusEquipId})`,
                type: 'read',
                tags: ['discharge', 'air', 'temp', 'sensor','displayInUi','modbus','system']
              },
        ];

        this.systemPoints = this.systemPoints.concat(systemPoints);
		this.getCCUSettingData();
    }


	getSystemModbusSettings(mode: string, pointID: any, endPointType: string, profileEquipName: string = undefined) {
		if (pointID) {
			pointID.map((pId) => this.helperService.assemblePointIdData(pId, endPointType, mode, profileEquipName, 'update'));
			this.ccuSettingObj[mode] = {};
			this.ccuSettingObj[mode]['id'] = pointID;
			this.helperService.assemblePointIdData(pointID, endPointType, mode, profileEquipName, 'update');
		}
	}


	getSystemProfileType() {
		//In the following method we are filtering  all the system modbus profiles with the gateway ref 
		// then we are  grouping the equip refs based on the profile if it is EMR or BTU or VAV/DAB extenal ahu
		const combinedQuery = `gatewayRef==@${this.ahuRef} and (system and modbus and profile))`;
		this.siteService.findByQuery(combinedQuery)
			.subscribe(
				res => {
					//
					if (!res?.rows?.length) {
						return;
					}
					res = this.helperService.stripHaystackTypeMapping(res);

					const filteredRows = res.rows.filter(row => row.hasOwnProperty('profile') && row.profile !== undefined);
					// Handle no rows with profile defined
					if (filteredRows.length === 0) {
						return;
					}
					// Grouping the rows based on profile so profile name will come as key and object will come as value
					const groupedRows = filteredRows.reduce((data, row) => {
						if (!data[row.profile]) {
							data[row.profile] = [];
						}
						data[row.profile].push(row);
						return data;
					}, {});

					// based on profiles we are excuting the respective mentods
					Object.keys(groupedRows).forEach(profile => {
						if (profile === 'MODBUS_EMR') {
							this.checkIfSystemEMRIsPaired(groupedRows[profile]);
						}

						if (profile === 'MODBUS_BTU') {
							this.checkIfSystemBTUIsPaired(groupedRows[profile]);
						}

						if (profile === 'dabExternalAHUController' || profile === 'vavExternalAHUController') {
							this.checkIfSystemModbusIsPaired(groupedRows[profile]);
						}
					});
				},
				error => {
					console.error("Error occurred while fetching data:", error);
				}
			);
	}
	/**
	 *  Here this method calls addHighlightClass method
	 */
	selectCcu(ccuInfo, event?) {

		this.cleanUp();
		this.ccuId = this.stripHayStackTags(ccuInfo.id).split(' ')[0];
		this.addHighlightClass(this.ccuId);
	}
	/**
	 *  Here this method calls stripHaystackTypeMapping method in helperServce
	 */
	stripHayStackTags(dataInput) {
		return this.helperService.stripHaystackTypeMapping(dataInput);
	}
	/**
	 *  Here this method gets the ccu setting data
	 */
	getCCUSettingData() {
		this.siteService.getHaystackDataByQuery(this.filterQuery()).subscribe(({ rows }) => {
			if (rows?.length > 0) {
				this.mapIdFromResponse(rows);
				this.systemPoints.forEach(point => {
					if(point.id) {
						this.ccuSettingObj[point.pointName] = {};
						this.ccuSettingObj[point.pointName]['id'] = point.id;
						this.helperService.assemblePointIdData(point.id, point.type, point.pointName, undefined, 'update');
					}
				});

			}
			this.getCCUDetails();

		}, err => {
			this.getCCUDetails();
		});
	}

	mapIdFromResponse(rows){
		const domainNames = ['supplyAirTemperature1', 'supplyAirTemperature2', 'supplyAirTemperature3', 'averageSat', 'minSat', 'maxSat', 'ductStaticPressureSensor1_2', 'ductStaticPressureSensor2_2', 'ductStaticPressureSensor3_2', 'averagePressure', 'minPressure', 'maxPressure'];
		this.systemPoints = this.systemPoints.map(r => {
			rows.forEach(row => {
				let arr = 0;
				if (row?.domainName) {
					//Here we are specifically checking for vavAdvancedHybridAhuV2/dabAdvancedHybridAhuV2 profile to get the correct id for connect module points
					// As we are getting the samee domain name for connect module points that we need to show in the UI excluding above domianNames so we are adding this additionial condition check.
					if (this.checkForAdvanceAhuProfiles.indexOf(this.systemReconfigProfileName) > -1 && (row?.domainName == r?.domainName)) {
						if (row?.hasOwnProperty('connectModule') && domainNames.includes(row?.domainName)) {
							return;
						} else {
							r['id'] = row?.id?.replace('r:', '');
						}
					} else if (row?.domainName == r?.domainName) {
						r['id'] = row?.id?.replace('r:', '');
					}
				} else {
					/*
					Here the following if method is to check for Dab fully modulating ahu profile with dcwb
					*/
					if (r.filterTags?.length) {
						const hasAllFilterTags = r.filterTags?.every(tag => !(tag in row));
						if (hasAllFilterTags) {
							r?.tags?.forEach(tag => {
								if (row[tag]) {
								  arr += 1;
								}
							});
						}
					} else {
						r?.tags?.forEach(tag => {
							if (row[tag]) {
							  arr += 1;
							}
						});
					}
					if (r?.tags?.length == arr) {
					  r['id'] = row?.id?.replace('r:', '');
					}
				}
			});
			return r;
		});
	}

	filterQuery() {
		let concatQuery = 'point and ';
		const uniquePoints = [...new Set(this.systemPoints.map(item => item.query).filter(item => item))]
		uniquePoints.forEach((r, i) => {
			concatQuery += r + `${uniquePoints.length - 1 == i ? '' : ' or '}`
		});
		 return concatQuery;
	}

	getCCUDetails() {
		this.skeletonContentLoaded = true;
		this.getScheduleInfoForCCU();
		this.getUserSettingData();
		clearInterval(this.messagingSubscribe);
		//start the setInterval call to call the api to update the data change if any every 5 sec
        this.messagingSubscribe = setInterval(() => {
            this.helperService.clearWritablePointsUpdatedStatus();
            this.helperService.getPointData().subscribe(res => res);
        }, 10000);
	}

	/**
	 *  Here this method calls onControlChange() method on system mode change
	 */
	onSystemModeChange(event: any, mode: string) {
		this.onControlChange('systemMode', this.modeList.indexOf(mode), this.ccuSetting);
	}
	/**
	 *  Here this method calls onControlChange() method on slider value change
	 */
	onSliderValueChange(onSliderValueChange: any) {
		let sliderValue = String(onSliderValueChange.value);
		this.onControlChange('desiredComfortIndex', this.rangeSliderHack[sliderValue], this.ccuSetting);
	}
	/**
	 *  Here this method calls onControlChange() method on toggle button change
	 */
	onToggleBtnChange(toggleValue: any, mode: string) {
		const toggleBtnValue = (toggleValue.checked) ? 1 : 0;
		this.onControlChange(mode, toggleBtnValue, this.ccuSetting);
	}
	/**
	 *  Here this method calls onControlChange() method on number ticker change
	 */
	onNumberTickerChange(event: MatSelect, mode: string) {
		let numberValue = event.value;
		this.onControlChange(mode, numberValue, this.ccuSetting);
	}
	/**
	 *  Here this method calls handleInputChange() method in helperService
	 */
	onControlChange(mode, inputData: any, ccuSettingObj: any) {
		let data = []
		data.push({
			type: mode,
			value: inputData,
			priorityArray: ccuSettingObj[mode].priorityArray || []
		})
		this.helperService.handleInputChange(data);
	}
	/**
	 *  Here this method reverts the changed values
	 */
	revertChangedValues(paramList: any) {
		for (let i = paramList.length - 1; i >= 0; i--) {
			if (this.ccuSetting[paramList[i].mode]) {

				this.ccuSetting[paramList[i].mode].val = parseFloat(paramList[i].val);
				const cmRadioEle: any = document.getElementById('radio-' + this.modeList[this.ccuSetting.systemMode.val].toLowerCase());
				if (cmRadioEle) {
					cmRadioEle.checked = true;
				}
				if (paramList[i].mode == 'desiredComfortIndex') {
					this.desiredCIValue = this.ccuSetting[paramList[i].mode].val;
					this.desiredCISliderValue = this.rangeSliderHack[this.desiredCIValue];

				}
				else if (paramList[i].mode == 'compensateHumidity') {
					this.CompensateHumidity.checked = parseInt(paramList[i].val);
					this.CompensateHumidity._checked = parseInt(paramList[i].val);
				}
				else if (paramList[i].mode == 'demandResponse') {
					this.DemandResponse.checked = parseInt(paramList[i].val);
					this.DemandResponse._checked = parseInt(paramList[i].val);
				}
				else if (paramList[i].mode == 'smartPrepurge') {
					this.ccuSetting.smartPrepurge.val = parseInt(paramList[i].val);
				}
				else if (paramList[i].mode == 'smartPostpurge') {
					this.ccuSetting.smartPostpurge.val = parseInt(paramList[i].val);
				}
				else if (paramList[i].mode == 'enhancedVentilation') {
					this.ccuSetting.enhancedVentilation.val = parseInt(paramList[i].val);
				}
				else if (paramList[i].mode == 'minHumidity') {
					this.ccuSetting.minHumidity.val = parseInt(paramList[i].val);
				}
				else if (paramList[i].mode == 'maxHumidity') {
					this.ccuSetting.maxHumidity.val = parseInt(paramList[i].val);
				}
			}
			paramList.splice(i);
			this.helperService.revertPointValues.splice(i);
		}
	}
	/**
	 *  Here this filters and returns the equips
	 */
	getEquip(equipTags: any) {
		return this.buildings?.equips
			?.filter(equip => equipTags?.every(elem => equip?.markers?.indexOf(elem) > -1) && equip?._id == this.ahuRef)[0];
	}
	/**
	 *  Here this method checks if isCooling is true or false
	 */
	hasCooling() {

		// For VvaAdvancedHybridAhu based on the configuration settings we need to set the condition mode 
        // We no need to check the any analogues only with the relay selection weather the point is present or not need to check and display the mode
         //here in the  array coolingKeys containing the names of the keys that represent heating stages.
		 
		 if (this.checkForAdvanceAhuProfiles.indexOf(this.systemReconfigProfileName) > -1) {
			if (this.ccuSetting.hasOwnProperty('compositSignal')) {
				return true;
			} else {
				const coolingKeys = ['coolingstage1', 'coolingstage2', 'coolingstage3', 'coolingstage4', 'coolingstage5', 'satcoolingstage5', 'satcoolingstage1', 'satcoolingstage2', 'satcoolingstage3', 'satcoolingstage4', 'satcoolingstage5', 'satbasedcoolingcontrol', 'loadbasedcoolingcontrol',
					'connectmodulecoolingstage1', 'connectmodulecoolingstage2', 'connectmodulecoolingstage3', 'connectmodulecoolingstage4', 'connectmodulecoolingstage5', 'connectmoduleloadbasedcoolingcontrol'];
				const isCooling = this.checkStagesPresent(this.ccuSetting, coolingKeys);
				return isCooling;
			}
		}

		if (!this.ccuSetting || this.systemReconfigProfileName == 'dabExternalAHUController' || this.systemReconfigProfileName == 'vavExternalAHUController' ) return true;
		let keys = ["relay-1", "relay-2", "relay-3", "relay-4", "relay-5", "relay-6", "analog-out1", "analog-out3", "analog-out4", "daikin-heating", "daikin-cooling"];
		if (this.systemReconfigProfileName != "SYSTEM_DAB_ANALOG_RTU" && this.systemReconfigProfileName != "SYSTEM_VAV_ANALOG_RTU") keys.push('relay-7');
		let isCooling = false;
		for (let i = 0; i < keys.length; i++) {
			if (this.ccuSetting[keys[i]] && parseInt(this.ccuSetting[keys[i]].val)) {
				if(keys[i] == 'analog-out4' && (this.systemReconfigProfileName == 'DAB Advanced Hybrid AHU' || this.systemReconfigProfileName == 'VAV Advanced Hybrid AHU') && this.ccuSetting[keys[i]] && parseInt(this.ccuSetting[keys[i]].val) == 1) {
                    return isCooling = true;
                } else if (this.ccuSetting['dcwbEnabled'] && this.ccuSetting['dcwbEnabled'].val == 1) {
					if (keys[i] == "analog-out4") {
						return isCooling = true;
					}
				} else if (keys[i] == "analog-out1" || keys[i] == "daikin-cooling") {
					return isCooling = true;
				}
				else if (this.ccuSetting[keys[i] + "Mapping"] && parseInt(this.ccuSetting[keys[i] + "Mapping"].val) < 5) {
					return isCooling = true;
				}

			}
		}
		return isCooling;
	}
	/**
	 *  Here this method checks if isHeating is true or false
	 */
	hasHeating() {
		// For VvaAdvancedHybridAhu based on the configuration settings we need to set the condition mode 
        // We no need to check the any analogues only with the relay selection weather the point is present or not need to check and display the mode
        //here in the  array heatingKeys containing the names of the keys that represent heating stages.
		if (this.checkForAdvanceAhuProfiles.indexOf(this.systemReconfigProfileName) > -1) {
			if (this.ccuSetting.hasOwnProperty('compositSignal')) {
				return true;
			} else {
				const heatingKeys = ['heatingstage1', 'heatingstage2', 'heatingstage3', 'heatingstage4', 'heatingstage5','satheatingstage1', 'satheatingstage2', 'satheatingstage3', 'satheatingstage4', 'satheatingstage5','satbasedheatingcontrol','loadbasedheatingcontrol',
				'connectmoduleheatingstage1','connectmoduleheatingstage2','connectmoduleheatingstage3','connectmoduleheatingstage4','connectmoduleheatingstage5','connectmoduleloadbasedheatingcontrol'];
				const isHeating = this.checkStagesPresent(this.ccuSetting, heatingKeys);
				return isHeating;
			}
        }

		if (!this.ccuSetting || this.systemReconfigProfileName == 'dabExternalAHUController' || this.systemReconfigProfileName == 'vavExternalAHUController') return true;
		let keys = ["relay-1", "relay-2", "relay-3", "relay-4", "relay-5", "relay-6", "analog-out1", "analog-out3","analog-out4", "daikin-heating", "daikin-cooling"];
		if (this.systemReconfigProfileName != "SYSTEM_VAV_ANALOG_RTU" && this.systemReconfigProfileName != "SYSTEM_DAB_ANALOG_RTU") keys.push('relay-7');
		let isHeating = false;
		for (let i = 0; i < keys.length; i++) {
			if (this.ccuSetting[keys[i]] && parseInt(this.ccuSetting[keys[i]].val)) {
				if(keys[i] == 'analog-out4' && (this.systemReconfigProfileName == 'DAB Advanced Hybrid AHU' || this.systemReconfigProfileName == 'VAV Advanced Hybrid AHU') && this.ccuSetting[keys[i]] && parseInt(this.ccuSetting[keys[i]].val) == 1) {
                    return isHeating = true;
                } else if (keys[i] == "analog-out3" || keys[i] == "daikin-heating") {
					return isHeating = true;
				}
				else if (this.ccuSetting[keys[i] + "Mapping"] && parseInt(this.ccuSetting[keys[i] + "Mapping"].val) >= 5 && parseInt(this.ccuSetting[keys[i] + "Mapping"].val) < 10) {
					return isHeating = true;
				}

			}
		}
		return isHeating;
	}
	/**
	 *  Here this method calls hasCooling() or hasHeating() based on the mode
	 */
	isShowCMmode(mode) {
		if (mode == 1) {
			return this.hasCooling() && this.hasHeating();
		} else if (mode == 2) {
			return this.hasCooling()
		} else if (mode == 3) {
			return this.hasHeating();
		}
		return true;
	}

	      //checkStagesPresent that takes two parameters:
      // 1. systemReconfigurationSettings: an object representing the system user intent data 
      // 2. requiredKeys: an array containing the names of the keys to be checked for existence It might be Heating Keys or cooling Keys
      checkStagesPresent(systemReconfigurationSettings, requiredKeys) {
		for (const key of requiredKeys) {
			if (systemReconfigurationSettings?.hasOwnProperty(key)) {
				// If the key is found in the systemReconfigurationSettings object, return true it might be Cooling key or heating key
				return true;
			}
		}
		// If none of the required keys are found in the systemReconfigurationSettings object, return false  it might be isCooling key or isHeating
		return false;
	}

	searchCcu(value) {
		let search = value.trim();
		let filter = search.toUpperCase();
		let ul = document.getElementsByClassName("systemoperations-wrapper__nav__ccuList");
		let li = ul[0].querySelectorAll('li');
		for (let i = 0; i < li.length; i++) {
			let bName = li[i];
			let txtValue = bName.title;
			if (txtValue.length) {
				if (txtValue.toUpperCase().indexOf(filter) > -1) {
					li[i].style.display = "";
				} else {
					li[i].style.display = "none";
				}
			}
		}
	}

	getScheduleInfoForCCU() {
		const mappedZones =
			this.ccusWithPairedZones.find((ccusWithPairedZone: any) => ccusWithPairedZone.id == this.ccuId)?.pairedZoneList || [];
		let zoneList = this.buildings?.zones?.filter((option: any) => mappedZones.indexOf(option._id) !== -1);
		// Ignore the zones have 'sense', emr, pid, modbus.
		zoneList = zoneList?.map(zone => {
			return this.checkProfileTypeOfZones(zone);
		}).filter(r => r);
		this.intrinsicScheduleData['zones'] = this.getZonesSchedulesByCCURef(zoneList);
		this.intrinsicScheduleData = ObjectUtil.deepCopy(this.intrinsicScheduleData);
		// Get Zone Point Ids for all the zones.
		this.getAllZonePointIds(zoneList);
	}

	checkProfileTypeOfZones(zone) {
        const profile = this.deviceHelper.getZoneProfile([zone]);
        const includeProfiles = ['vav', 'dab', 'ti', 'dualDuct', 'series', 'parallel', 'otn' , 'bpos'];
        const found = profile.find(item => includeProfiles.includes(item.profileType));
        if (found) {
            return zone;
        }
    }

	getSchId(zoneId) {
		let schId = [];
		this.buildings.floors.forEach(element => {
			const found = element.entities.find(r => r._id == zoneId);
			if (found && Object.keys(found) && Object.keys(found).length) {
				schId = [found['referenceIDs']['schedule']];
			}	
		});
		return schId;
	}

	getZonesSchedulesByCCURef(zoneList) {
		if(zoneList){
			return zoneList.map((zone) => {
				zone['scheduleId'] = this.getSchId(zone._id);
				zone['zoneSpecialSchIds'] = this.helperService.getPointIdbyTags(this.buildings, ['zone', 'schedule', 'range', 'special'], zone._id)
				zone['scheduleType'] = this.helperService.getPointIdbyTags(this.buildings, ['zone', 'scheduleType'], zone._id);
				zone['zoneVacationIds'] = this.helperService.getPointIdbyTags(this.buildings, ['schedule', 'range', 'vacation'], zone._id);
				return zone;
			});
		}	
	}

	getAllZonePointIds(zoneList) {
		if(zoneList){
			zoneList.forEach(zone => {
				this.getZoneSettings('zoneSchedule', zone['scheduleId'], 'schedule', zone.name);
				this.getZoneSettings('scheduleType', zone['scheduleType'], 'write', zone.name);
				this.getZoneSettings('zoneSpecialSchedule', zone['zoneSpecialSchIds'], 'vacation', zone.name);
				this.getZoneSettings('zoneVacations', zone['zoneVacationIds'], 'vacation', zone.name);
			});
		}
		this.getZoneSettings('buildingSchedule', this.helperService.getPointIdbyTags(this.buildings.schedule, ['building', 'schedule', 'days']) , null);
		this.getZoneSettings('buildingVacations', this.helperService.getPointIdbyTags(this.buildings.schedule, ['building', 'schedule', 'range', 'vacation'], null), 'vacation');
		this.getZoneSettings('buildingOccupancy', this.helperService.getPointIdbyTags(this.buildings.buildingOccupancy, ['building', 'occupancy','days']) , 'schedule');
		this.getZoneSettings('buildingSpecialSchedule', this.helperService.getPointIdbyTags(this.buildings.schedule, ['building', 'special', 'schedule', 'range'], null), 'vacation');
	}

	getZoneSettings(settingType: string, pointID: any, endPointType: string, profileEquipName: string = undefined) {
		if (pointID) {
			pointID = pointID.filter(point=>point);
			pointID.map((pId) => this.helperService.assemblePointIdData(pId, endPointType, settingType, profileEquipName, 'update'));
		}
	}

	fetchCCuListWithPairedZones(ccu) {
		this.ccusWithPairedZones = [];

		this.siteService.getZonesListPairedOnCcu(this.helperService.stripHaystackTypeMapping(ccu.ahuRef)).subscribe((res) => {
			if (res && res.rows && res.rows.length > 0) {
				let zoneList = [];
				res.rows.forEach((row) => {
					let zoneId = this.helperService.stripHaystackTypeMapping(row.roomRef);
					let zoneExists = zoneList.filter((x) => { if (x.zoneId == zoneId) { return x; } });
					if (!zoneExists || (zoneExists.length == 0))
						zoneList.push(zoneId);
				});
				this.ccusWithPairedZones.push({ 'id': this.helperService.stripHaystackTypeMapping(ccu.id), 'CCUName': ccu.dis, 'ahuRef': this.helperService.stripHaystackTypeMapping(ccu.ahuRef), 'pairedZoneList': zoneList });
			}
			else {
				this.ccusWithPairedZones.push({ 'id': this.helperService.stripHaystackTypeMapping(ccu.id), 'CCUName': ccu.dis, 'ahuRef': this.helperService.stripHaystackTypeMapping(ccu.ahuRef), 'pairedZoneList': [] });
			}
		}, err => {
			console.log(err);
		});
	}

	setIntrinsicScheduleData(data: any) {
		if (!data) return;
		const intrinsicScheduleData = {};
        intrinsicScheduleData['buildingSchedules'] = data?.buildingOccupancy?.id ? data.buildingOccupancy?.val : [];
		intrinsicScheduleData['buildingVacations'] = data?.buildingVacations && data?.buildingVacations?.length ? data.buildingVacations : [];
		intrinsicScheduleData['buildingSpecialSchedule'] = data?.buildingSpecialSchedule?.length ? data?.buildingSpecialSchedule : [];
		const mappedZones =
			(this.ccusWithPairedZones.find((ccusWithPairedZone: any) => ccusWithPairedZone.id == this.ccuId) || {}).pairedZoneList || [];
		let zoneList = this.buildings.zones.filter((option: any) => mappedZones.indexOf(option._id) !== -1);
		intrinsicScheduleData['zones'] = [];
		zoneList.forEach(zoneInfo => {
			if (data[zoneInfo.name] && data[zoneInfo.name]['scheduleType'] && !(data[zoneInfo.name]['zoneSchedule']?.error) && (data[zoneInfo.name]['scheduleType']?.val > -1)) {
				const obj = {
					id: zoneInfo._id,
					name: zoneInfo.name,
					scheduleType: data[zoneInfo.name]?.scheduleType?.val,
					zoneSchedule: data[zoneInfo.name]?.zoneSchedule,
					zoneVacations: data[zoneInfo.name]?.zoneVacations,
					zoneSpecialSchedule: data[zoneInfo.name]?.zoneSpecialSchedule
				};
				intrinsicScheduleData['zones'].push(obj);
			}
		});
		if(!ObjectUtil.isEqual(this.intrinsicScheduleData,intrinsicScheduleData)) {
            this.intrinsicScheduleData = ObjectUtil.deepCopy(intrinsicScheduleData);
        };
	}

	getNodes(arr, tags) {
		if (!arr || (Array.isArray(arr) && !arr.length)) {
			return [];
		}
		return arr.filter((_filterItem) => _filterItem)
			.filter((_item) => {
				const found = (_item.markers && Array.isArray(_item.markers)) ? tags.every(elem => _item.markers.indexOf(elem) > -1) : false;
				if (found) {
					return _item;
				}
			})
	}

	isDataLoaded(data: any, equipStatus: boolean = false) {
        return data ? this.helperService.isDataLoaded(data, equipStatus) : '';
    }

	checkCertificationAccess(view) {
		return this.authService.isUserAllowedToCheck(view) && this.showRemoteAccess;
	}

	setFlags(flags) {
		this.showRemoteAccess = flags.hasOwnProperty('remote-ccu-access') ? flags['remote-ccu-access'].value : false;
	  }
	  
	  
	useFeatureToggles() {
		this.featureToggleService.featureFlagsSubject.subscribe((flags) => {
			this.setFlags(flags);
		});
		this.featureToggleService.getFlags();
	}

    //Advanced Ahu User Intent
    getSupplyAirflowTempValue(ccuSetting) {
        const val = ccuSetting?.supplyAirflowTempControl?.val;
        const propertyNames = ['supplyAirTemperature1', 'supplyAirTemperature2', 'supplyAirTemperature3', 'averageSat', 'satSpMin', 'satSpMax'];
        switch (val) {
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                const propertyName = propertyNames[val];
                return ccuSetting.hasOwnProperty(propertyName) && ccuSetting[propertyName]?.val != null ? ccuSetting[propertyName].val : '-';
            default:
                return '-';
        }
    }

	getOperatingMode(ccuSetting) {
        if (!ccuSetting || !ccuSetting.advancedAhuOperatingMode) {
            return '-';
        }
        switch (Number(ccuSetting.advancedAhuOperatingMode.val)) {
            case 0:
                return 'Off';
            case 1:
                return 'Cooling';
            case 2:
                return 'Heating';
            case 3:
                return 'Temp Dead';
            default:
                return '-';
        }
    }

    getDischargeAirflowTempValue(ccuSetting) {
		const pressureFanControl = ccuSetting?.pressureBasedFanControl?.val;
		const analogInput1 = ccuSetting?.analog1InputAssociation?.val;
		const analogInput2 = ccuSetting?.analog2InputAssociation?.val;
		const busAdd0 = ccuSetting?.pressureSensorBusAdd0?.val;

		const checkSensors = (sensors) => sensors.map(sensor => ccuSetting?.[sensor]?.val).find(val => val !== undefined) ?? '-';

		switch (pressureFanControl) {
			case 0:
				if ([12, 13, 14].includes(analogInput1) || [12, 13, 14].includes(analogInput2)) {
					return checkSensors(['ductStaticPressureSensor1_1', 'ductStaticPressureSensor1_2', 'ductStaticPressureSensor1_10']);
				}
				if (busAdd0 === 1) {
					return ccuSetting?.ductStaticPressureSensor1_2?.val ?? '-';
				}
				break;
			case 1:
				if ([15, 16, 17].includes(analogInput1) || [15, 16, 17].includes(analogInput2)) {
					return checkSensors(['ductStaticPressureSensor2_1', 'ductStaticPressureSensor2_2', 'ductStaticPressureSensor2_10']);
				}
				if (busAdd0 === 2) {
					return ccuSetting?.ductStaticPressureSensor2_2?.val ?? '-';
				}
				break;
			case 2:
				if ([18, 19, 20].includes(analogInput1) || [18, 19, 20].includes(analogInput2)) {
					return checkSensors(['ductStaticPressureSensor3_1', 'ductStaticPressureSensor3_2', 'ductStaticPressureSensor3_10']);
				}
				if (busAdd0 === 3) {
					return ccuSetting?.ductStaticPressureSensor3_2?.val ?? '-';
				}
				break;
			case 3:
				return ccuSetting?.averagePressure?.val ?? '-';
			case 4:
				return ccuSetting?.minPressure?.val ?? '-';
			case 5:
				return ccuSetting?.maxPressure?.val ?? '-';
		}

		return '-';
	}

	      /**  In this method we will ccheck for loaded based point sif load based ispaired we will not show 
      supply air temp section in the user intent*/
	  shouldDisplaySupplyAirTemp() {
		const  loadBasedConfigCheck= [
			'satbasedheatingcontrol','satbasedcoolingcontrol'
        ];
        for (let prop of loadBasedConfigCheck) {
            if (this.ccuSetting.hasOwnProperty(prop)) {
                return true;
            }
        }
    }

    ahuProfilesGlobalisationCheck() {
        const userTempPreference = this.authService.getLoggedInUserPreferences();
        if (this.userTempPreference === "°C") {
            ["airTempHeatingSp", "airTempCoolingSp", 'supplyAirTemperature1', 'supplyAirTemperature2', 'supplyAirTemperature3', 'averageSat', 'satSpMin', 'satSpMax'].forEach(key => {
                if (this.ccuSetting[key]?.val !== undefined && this.ccuSetting[key]?.val !== null) {
                    let value = this.ccuSetting[key].val.split(' ')[0];
                    this.ccuSetting[key].val = this.unitService.fahrenheitToCelecius(value, key) + this.userTempPreference;
                }
            });
        }
		if (userTempPreference.airPressureUnit !== 'inH₂O') {
            ['ductStaticPressureSetPoint', 'ductStaticPressureSensor1_1', 'ductStaticPressureSensor1_2', 'ductStaticPressureSensor1_10',
                'ductStaticPressureSensor2_1', 'ductStaticPressureSensor2_2', 'ductStaticPressureSensor2_10',
                'ductStaticPressureSensor3_1', 'ductStaticPressureSensor3_2', 'ductStaticPressureSensor3_10', 'averagePressure', 'minPressure', 'maxPressure'].forEach(key => {
                    if (this.ccuSetting[key]?.val !== undefined && this.ccuSetting[key]?.val !== null) {
                        let value = this.ccuSetting[key].val.split(' ')[0];
                        this.ccuSetting[key].val = this.unitService.checkForCFMConversion(value, userTempPreference); + this.userTempPreference;
                    }
                });
        }
    }


}
