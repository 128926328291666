<div class="account-details-container">
    <div class="section-header">Personal Details</div>

    <div class="account-form">
        <form [formGroup]="accountForm">
            <div class="row">
                <div class="col">
                    <label>First Name</label>
                    <div class="m-l-10 flex-grow">
                        <mat-form-field class="w-100" [floatLabel]="'never'">
                            <input matInput trim="blur" type="text" formControlName="firstName" placeholder=""
                                autocomplete="off" />
                            <span matSuffix> <i class="fas fa-pen edit-icon" aria-hidden="true"></i></span>
                            <mat-error class="err-text">
                                <ng-container *ngIf="!accountForm.controls.firstName.hasError('forbiddenName') && accountForm.controls.firstName.invalid && !accountForm.controls.firstName.hasError('pattern') && (accountForm.controls.firstName.dirty || accountForm.controls.firstName.touched)">
                                First Name is required
                                </ng-container>
                                <ng-container *ngIf="!accountForm.controls.firstName.hasError('forbiddenName') && accountForm.controls.firstName.hasError('pattern')">
                                    <ng-container *ngTemplateOutlet="errorMsgTemplate;   
                                    context: { name:'First Name' }">
                                    </ng-container>
                                </ng-container>
                               <ng-container *ngIf="accountForm.controls.firstName.hasError('forbiddenName')">
                                First Name cannot start with special characters.
                                </ng-container>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col">
                    <label>Last Name</label>
                    <div class="m-l-10 flex-grow">
                        <mat-form-field class="w-100" [floatLabel]="'never'">
                            <input matInput type="text" trim="blur" formControlName="lastname" placeholder=""
                                autocomplete="off" />
                            <span matSuffix> <i class="fas fa-pen edit-icon" aria-hidden="true"></i></span>
                            <mat-error class="err-text">
                                <ng-container *ngIf="!accountForm.controls.lastname.hasError('forbiddenName') && accountForm.controls.lastname.hasError('pattern')">
                                    <ng-container  *ngTemplateOutlet="errorMsgTemplate;   
                                    context: { name:'Last Name' }">
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="accountForm.controls.lastname.hasError('forbiddenName')">
                                    Last Name cannot start with special characters.
                                </ng-container>
                            </mat-error>
                        </mat-form-field>

                    </div>
                </div>

                <ng-template #errorMsgTemplate let-name='name'>
                    Special characters are not allowed in {{name}} Allowed characters[hyphen(-), underscore(_), space()].
                </ng-template>

                <div class="col">
                    <label>Email</label>
                    <div class="m-l-10 flex-grow">
                        <mat-form-field class="w-100" [floatLabel]="'never'">
                            <input matInput type="text" trim formControlName="email" placeholder=""
                                autocomplete="off" />
                            <span matSuffix> <i class="fas fa-pen edit-icon" aria-hidden="true"></i></span>
                            <mat-error
                                *ngIf="accountForm.controls.email.hasError('required') && (accountForm.controls.email.dirty || accountForm.controls.email.touched)">
                                Email is required
                            </mat-error>
                            <mat-error
                                *ngIf="accountForm.controls.email.hasError('email') && (accountForm.controls.email.dirty || accountForm.controls.email.touched)">
                                Not a valid email
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">


                <div class="col">
                    <label class="p-b-5">Country</label>
                    <div class="m-l-10 flex-grow">
                        <puc-select-dropdown class="w-100" [placeholderLabel]="'Select Country'" [data]="countryList"
                            [displayKey]="'displayName'" [valueKey]="'iso'" [search]="true" formControlName="country"
                            (valueChanged)="phoneNumberValidator(accountForm)">
                        </puc-select-dropdown>
                        <br>
                        <mat-error *ngIf="accountForm.controls?.country.hasError('requiredWithPhoneNum')" style="scale: .8; float: left;">
                            <ng-container>
                                Country code is required
                            </ng-container>
                        </mat-error>
                    </div>
                </div>

                <div class="col" style="display: block !important;">
                    <div class="w-100" style="display: flex;">
                        <div style="width: 40px; padding-top: 20px;">
                            <label>Phone</label>
                        </div>
                        <div class="m-l-10" style="width: calc(100% - 40px);">
                            <mat-form-field class="w-100" [floatLabel]="'never'">
                                <input matInput type="text" formControlName="phone" placeholder=""
                                    (keypress)="numberOnly($event)" autocomplete="off"
                                    (ngModelChange)="phoneNumberValidator(accountForm)" />
                                <span matSuffix> <i class="fas fa-pen edit-icon"></i></span> <br>
    
                                <mat-error *ngIf=" accountForm.controls.country.touched  || accountForm.controls.phone.touched || (accountForm.controls.phone.invalid && ( accountForm.controls.phone.touched))  ">
                                    <ng-container *ngIf="accountForm.controls?.phone.hasError('phoneNumberInvalid') ">
                                        Invalid Phone number
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngIf="phoneNumberChanged && hasPhoneNumber" class="w-100">
                        <div class="opt-in-container">
                            <mat-checkbox class="opt-in-checkbox" formControlName="smsOptIn"></mat-checkbox>
                                I agree to receive automated alert notification messages from {{ partnerName }} 
                                at the phone number provided. Message and data rates may apply. Reply STOP to opt-out.
                                <br><br>
                                <a target="_blank" [href]="termsOfService" *ngIf="termsOfService">Terms of Service</a>
                                <br>
                                <a target="_blank" [href]="privacyStatement" *ngIf="privacyStatement">Privacy Statement</a>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <span class="section-header"> User Preferences </span>
            </div>

            <div class="row">
                <div class="col">
                    <label class="p-b-4">Temperature Unit</label>
                    <div class="m-l-10 flex-grow">
                        <puc-select-dropdown class="w-100 notranslate" [disableTranslation]="true" [data]="unitService.temperatureList" [valueKey]="'name'"
                            [displayKey]="'name'" [search]="false" formControlName="temperature"></puc-select-dropdown>
                    </div>
                </div>
                <div class="col">
                    <label class="p-b-4">Energy Consumption Unit</label>
                    <div class="m-l-10 flex-grow">
                        <puc-select-dropdown class="w-100 notranslate" [disableTranslation]="true" [data]="unitService.energyConsumptionList" [valueKey]="'name'"
                            [displayKey]="'name'" [search]="false" formControlName="energyConsumption">
                        </puc-select-dropdown>
                    </div>
                </div>
                <div class="col">
                    <label class="p-b-4">Airflow Volume Unit</label>
                    <div class="m-l-10 flex-grow">
                        <puc-select-dropdown class="w-100 notranslate" [disableTranslation]="true" [data]="unitService.airflowVolumeList" [valueKey]="'name'"
                            [displayKey]="'name'" [search]="false" formControlName="airflowVolume">
                        </puc-select-dropdown>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <label class="p-b-4">Air Pressure Unit</label>
                    <div class="m-l-10 flex-grow">
                        <puc-select-dropdown class="w-100 notranslate" [disableTranslation]="true" [data]="unitService.airPressureList" [valueKey]="'name'"
                            [displayKey]="'name'" [search]="false" formControlName="airPressure"></puc-select-dropdown>
                    </div>
                </div>
                <div class="col">
                    <label class="p-b-4">Water Pressure Unit</label>
                    <div class="m-l-10 flex-grow">
                        <puc-select-dropdown class="w-100 notranslate" [disableTranslation]="true" [data]="unitService.waterPressureList" [valueKey]="'name'"
                            [displayKey]="'name'" [search]="false" formControlName="waterPressure">
                        </puc-select-dropdown>
                    </div>
                </div>
                <div class="col">
                    <label class="p-b-4">Water Flow Unit</label>
                    <div class="m-l-10 flex-grow">
                        <puc-select-dropdown class="w-100 notranslate" [disableTranslation]="true" [data]="unitService.waterFlowList" [valueKey]="'name'"
                            [displayKey]="'name'" [search]="false" formControlName="waterFlow"></puc-select-dropdown>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <label class="p-b-4">Language Preference</label>
                    <div class="m-l-10 flex-grow">
                        <puc-select-dropdown class="w-200 language-preference notranslate" [placeholderLabel]="'Select Language'"
                            [data]="languageOptions" [displayKey]="'name'" [searchInExistingList]="true" [valueKey]="'value'"
                            formControlName="language"  [disableTranslation]="true">
                        </puc-select-dropdown>
                    </div>
                </div>
            </div>

            <div class="btn-ctnr">
                <button mat-stroked-button (click)="cancelChanges()">Cancel</button>
                <button mat-stroked-button [disabled]="accountForm.invalid || !accountForm.dirty || !optInValid"
                    (click)="onSubmit()">Update</button>
            </div>
        </form>
    </div>
</div>