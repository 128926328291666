import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserManagementLayoutComponent } from './components/user-management-layout/user-management-layout.component';
import { SharedModule } from '@/shared/shared.module';
import { Routes, RouterModule } from '@angular/router';
import { UserManagementTableComponent } from './components/user-management-table/user-management-table.component';
import { AddUsersComponent } from './components/add-users/add-users.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PucPipesModule, PucDirectivesModule, CommonUiComponentsModule } from '@75f/portal-ui-components';
import { UserManagementModalComponent } from './components/user-management-modal/user-management-modal.component';
import { TippyModule, tooltipVariation, popperVariation } from '@ngneat/helipopper';
import { InstallerLevelsComponent } from './components/installer-levels/installer-levels.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
const routes: Routes = [
    {
        path: '', component: UserManagementLayoutComponent
    }
];
@NgModule({
    declarations: [UserManagementLayoutComponent, UserManagementTableComponent, AddUsersComponent, UserManagementModalComponent, InstallerLevelsComponent],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        NgxSkeletonLoaderModule,
        PucPipesModule,
        PucDirectivesModule,
        ReactiveFormsModule,
        RouterModule.forChild(routes),
        PucDirectivesModule,
        CommonUiComponentsModule,
        TippyModule.forRoot({
            defaultVariation: 'tooltip',
            variations: {
                tooltip: tooltipVariation,
                popper: popperVariation,
            }
        })
    ],
    entryComponents:[UserManagementModalComponent]
})
export class UserManagementLayoutModule { }
