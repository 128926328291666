import { AuthenticationService } from '@75f/portal-ui-components';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})

export class HelperServiceFacilisight {
	constructor(private authService: AuthenticationService) { }
	getGroupedZones(res, item) {
		let rooms = [];
		const floors = [];
		res.rows.forEach(_item => {
			if (_item.room) {
				rooms.push(_item);
			} else if (_item.floor) {
				floors.push(_item);
			}
		});
		if (!item['zoneList']) {
			item['zoneList'] = [];
		}
		let obj = {};
		floors.forEach(floor => {
			obj = {
				floors: floors.map(_item => {
					if (floor.id == _item.id) {
						return {
							floorName: _item.dis, floorId: floor.id, zones: rooms.map(room => {
								if (room.floorRef == _item.id) {
									return { zoneName: room.dis, zoneId: room.id }
								}
							}).filter(zone => zone)
						}
					}
				})
			};
			obj = this.removeUndefinedVals(obj);
			item['zoneList'].push(obj)
		});
		return item['zoneList'];
	}

	removeUndefinedVals(obj) {
		// Filtering undefined values.
		if (obj['floors'] && obj['floors']?.length) {
			obj['floors'] = obj['floors'].filter(flr => flr);
		}
		if (obj['zones'] && obj['zones']?.length) {
			obj['zones'] = obj['zones'].filter(zone => zone)
		}
		return obj;
	}

	checkCertification(view,showRemoteAccess): boolean {
        switch (view) {
            case 'remote-ccu-access':
                return showRemoteAccess && this.authService.isUserAllowedToCheck(view);
            case 'building-options':
                return this.authService.isUserAllowedToCheck('BuildingOptions');
            default:
                return true;
        }
    }

	setLanguagePrefToggle(status) {
		localStorage.setItem('languagePreferenceEnable', status);
	}

	getLanguagePrefToggle() {
		return localStorage.getItem('languagePreferenceEnable');
	}
}
