<div class="dialogTitle">Edit</div>
<div class="form-div" [formGroup]="userForm">
	<div class="blockMargin">
		<div class="row">
			<div class="col">
				<div class="m-l-10 flex-grow">
					<mat-form-field class="w-100" [floatLabel]="'never'">
						<input id="edit-user-emailId" matInput type="text" trim formControlName="emailId" placeholder="Email Id"
							(ngModelChange)="formValidator(userForm)" autocomplete="off" />
						<span class="required-field" matPrefix>*</span>
						<mat-error
							*ngIf="userForm.controls.emailId.hasError('required') && (userForm.controls.emailId.dirty || userForm.controls.emailId.touched)">
							Email is required
						</mat-error>
						<mat-error
							*ngIf="userForm.controls.emailId.hasError('email') && (userForm.controls.emailId.dirty || userForm.controls.emailId.touched)">
							Not a valid email
						</mat-error>
					</mat-form-field>
				</div>
			</div>

			<div class="col">
				<div class="m-l-10 flex-grow">
					<mat-form-field class="w-100" [floatLabel]="'never'">
						<input id="edit-user-firstName" matInput type="text" trim="blur" formControlName="firstName" placeholder="First Name"
							(ngModelChange)="formValidator(userForm)" autocomplete="off" />
						<span class="required-field" matPrefix>*</span>
							<mat-error class="err-text">
								<ng-container *ngIf="!userForm.controls.firstName.hasError('forbiddenName') && userForm.controls.firstName.invalid && !userForm.controls.firstName.hasError('pattern') && (userForm.controls.firstName.dirty || userForm.controls.firstName.touched)">
								First Name is required
								</ng-container>
								<ng-container *ngIf="!userForm.controls.firstName.hasError('forbiddenName') && userForm.controls.firstName.hasError('pattern')">
									<ng-container *ngTemplateOutlet="errorMsgTemplate;   
									context: { name:'First Name' }">
									</ng-container>
								</ng-container>
							   <ng-container *ngIf="userForm.controls.firstName.hasError('forbiddenName')">
								First Name cannot start with special characters.
								</ng-container>
							</mat-error>
					</mat-form-field>
				</div>
			</div>

			<div class="col">
				<div class="m-l-10 flex-grow">
					<mat-form-field class="w-100" [floatLabel]="'never'">
						<input id="edit-user-lastName" matInput type="text" trim="blur" formControlName="lastName" placeholder="Last Name"
							autocomplete="off" />
					</mat-form-field>
					<mat-error class="err-text">
						<ng-container *ngIf="!userForm.controls.lastName.hasError('forbiddenName') && userForm.controls.lastName.hasError('pattern')">
							<ng-container  *ngTemplateOutlet="errorMsgTemplate;   
							context: { name:'Last Name' }">
							</ng-container>
						</ng-container>
						<ng-container *ngIf="userForm.controls.lastName.hasError('forbiddenName')">
							Last Name cannot start with special characters.
						</ng-container>
					</mat-error>
				</div>
			</div>
		</div>

		<ng-template #errorMsgTemplate let-name='name'>
			Special characters are not allowed in {{name}} Allowed characters[hyphen(-), underscore(_), space()].
		</ng-template>
		
		<!-- Certification Level Selections -->
		<div class="row p-b-5 p-l-15" *ngIf="isInstallerSelected">
		<div class="row">
			<h3 class="installer-section-title w-100">Certification Level</h3>
			<h6 class="installer-section-text w-100">Certified users will have access to advanced features mentioned below.
				Non-certified user will have access to {{appName}}</h6>
		</div>
		<fs-installer-levels class="w-100 row" [control]="userForm.get('certificationLevel')"></fs-installer-levels>
		</div>
		
		<ng-template #accessInfoToolTip>
			<div class="tooltip">
				<div class="tooltip-header entity-tooltip entity-tooltip">
					<h5 class="tooltip-header">Access to</h5>
					<div class="tool-text">
						<span class="sub-text" *ngIf="isNonCertifiedUser">View Only Facilisight</span>
						<span class="sub-text" *ngIf="!isNonCertifiedUser">Facilisight</span><br>
						<span class="feature-options" *ngFor="let item of filteredFeatureItems">
							- {{item}} <br>
						</span>
					</div>
				</div>
			</div>
		</ng-template>
		
		<!-- Unit -->
		<div class="row">
			<div class="col">
				<label>Temperature Unit</label>
				<div class="m-l-10 flex-grow">
					<puc-select-dropdown class="w-100 notranslate" [disableTranslation]="true" [data]="unitService.temperatureList" [valueKey]="'name'"
						[displayKey]="'name'" [search]="false" formControlName="temperature"></puc-select-dropdown>
				</div>
			</div>
			<ng-container *ngIf="!isOccupant">
				<div class="col">
					<label>Energy Consumption Unit</label>
					<div class="m-l-10 flex-grow">
						<puc-select-dropdown class="w-50 notranslate" [disableTranslation]="true" [data]="unitService.energyConsumptionList" [valueKey]="'name'"
							[displayKey]="'name'" [search]="false" formControlName="energyConsumption">
						</puc-select-dropdown>
					</div>
				</div>
				<div class="col">
					<label>Airflow Volume Unit</label>
					<div class="m-l-10 flex-grow">
						<puc-select-dropdown class="w-100 notranslate" [disableTranslation]="true" [data]="unitService.airflowVolumeList" [valueKey]="'name'"
							[displayKey]="'name'" [search]="false" formControlName="airflowVolume"></puc-select-dropdown>
					</div>
				</div>
			</ng-container>
		</div>

		<div class="row" *ngIf="!isOccupant">
			<div class="col">
				<label>Air Pressure Unit</label>
				<div class="m-l-10 flex-grow">
					<puc-select-dropdown class="w-100 notranslate" [disableTranslation]="true" [data]="unitService.airPressureList" [valueKey]="'name'"
						[displayKey]="'name'" [search]="false" formControlName="airPressure"></puc-select-dropdown>
				</div>
			</div>
			<div class="col">
				<label>Water Pressure Unit</label>
				<div class="m-l-10 flex-grow">
					<puc-select-dropdown class="w-100 notranslate" [disableTranslation]="true" [data]="unitService.waterPressureList" [valueKey]="'name'"
						[displayKey]="'name'" [search]="false" formControlName="waterPressure"></puc-select-dropdown>
				</div>
			</div>
			<div class="col">
				<label>Water Flow Unit</label>
				<div class="m-l-10 flex-grow">
					<puc-select-dropdown class="w-100 notranslate" [disableTranslation]="true" [data]="unitService.waterFlowList" [valueKey]="'name'"
						[displayKey]="'name'" [search]="false" formControlName="waterFlow"></puc-select-dropdown>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col">
				<label>Language</label>
				<div class="m-l-10 flex-grow">
					<puc-select-dropdown class="w-200 language-preference notranslate" [placeholderLabel]="'Select Language'"
						[data]="laguageOptions" [displayKey]="'name'" [searchInExistingList]="true" [valueKey]="'value'"
						formControlName="language" [disableTranslation]="true">
					</puc-select-dropdown>
				</div>
			</div>
		</div>

		<ng-container *ngIf="isOccupant">
			<h2 class="text-uppercase p-5-0 sub-head">Zone Access</h2>
			<ul class="list-none d-flex flex-direction-row building-info flex-wrap">
				<li class="item d-flex  m-l-10 m-r-10" *ngFor="let site of data?.zoneList;let i =index" >
					<div class="ctnr">
							<div *ngFor="let floor of site?.floors" class="floor">{{floor?.floorName}}
								<div class="name notranslate">
									<span *ngFor="let zone of floor?.zones; let i=index" id="{{'zone-'+i}}">{{i != 0 ? ', ':''}}{{zone?.zoneName}}</span>
								</div>
							</div>
					</div>
				</li>
				<li
					class="item d-flex flex-column justify-content-center align-items-center assign-new m-l-10 m-r-10">
					<div>
					<div id="reassign-zone" (click)="reassignClicked()">
						REASSIGN ZONES
						<span class="fm-user fas fa-plus"></span>
					</div>
					<div class="col">
						<div class="m-l-10 flex-grow fontDetails hidden-mat-select">
							<puc-multi-select-checkbox id="from-modal" [appendClass]="true" #multiSelect [form]="userForm" [formControlName]="'zones'"
								[placeHolder]="'Select Zones'" (items)="getSelectedItems($event)" [inputPlaceHolder]="'Search for Zones'"
								[groupedItems]="groupedZones" [duplicateGroupedItems]="duplicateGroupedZones">
							</puc-multi-select-checkbox>
						</div>
					</div>
				</div>
				</li>
			</ul>
		</ng-container>
	</div>
	<div class="row">
		<div class="col-xs-12 m-b-10">
			<div class="btn-ctnr m-t-10 m-r-5">
				<button id="edit-user-cancel" mat-stroked-button (click)="closeEdit(data)">Cancel</button>
				<button id="edit-user-save" mat-stroked-button [disabled]="userForm.invalid || userForm.untouched || userForm.pristine"
					(click)="updateUser(data)" type="submit">Save Edit</button>
			</div>
		</div>
	</div>
</div>