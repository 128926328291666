<div *ngIf="isSiteExists" class="container">
  <div class="heading-text">Role Details</div>
  <div class="role-ctnr p-l-20">

    <div class=" p-t-20"  *ngIf="orgs.length">
      <div class="role-name p-b-20">Organizational Facility Manager</div>
     
        <mat-accordion [multi]="true" *ngFor="let org of orgs">
          <mat-expansion-panel  (closed)="collapseOrg(org)" (opened)="expandOrg(org)" hideToggle>
            <mat-expansion-panel-header [expandedHeight]="'30px'" [collapsedHeight]="'30px'"
            [class.br-btm]="!orgSites[org]['open']"
              (keydown.Space)="$event.stopImmediatePropagation();">
                <div class="p-l-10 p-r-10">
                  <i *ngIf="!orgSites[org]['open']"class="fas fa-caret-right"></i>
                  <i *ngIf="orgSites[org]['open']" class="expanded-color fas fa-sort-down"></i>
                  <span class="p-l-10 p-r-10 notranslate">{{org}}</span>
              </div> 
            </mat-expansion-panel-header>

            <div class="exp-panel-content">
              <ng-container [ngTemplateOutlet]="sitesTblRows"
              [ngTemplateOutletContext]="{ $implicit: orgSites[org].sites}">
              </ng-container>
            </div>
          </mat-expansion-panel>
         
        </mat-accordion>
    </div>

    <div class=" p-t-20"  *ngIf="primarySites.length">
      <div class="role-name">Primary Facility Manager</div>
      <ng-container [ngTemplateOutlet]="sitesTblRows"
        [ngTemplateOutletContext]="{ $implicit: primarySites}">
        </ng-container>
    </div>

    <div class=" p-t-20"  *ngIf="secondarySites.length">
      <div class="role-name">Secondary Facility Manager</div>
        <ng-container [ngTemplateOutlet]="sitesTblRows"
        [ngTemplateOutletContext]="{ $implicit: secondarySites}">
        </ng-container>
    </div>

    <div class=" p-t-20"  *ngIf="vofmSites.length">
      <div class="role-name">View Only Facility Manager</div>
        <ng-container [ngTemplateOutlet]="sitesTblRows"
        [ngTemplateOutletContext]="{ $implicit: vofmSites}">
        </ng-container>
    </div>

    <div class=" p-t-20"  *ngIf="primaryInstaller.length">
      <div class="role-name">Primary Installer</div>
        <ng-container [ngTemplateOutlet]="sitesTblRows"
        [ngTemplateOutletContext]="{ $implicit: primaryInstaller}">
        </ng-container>
    </div>

    <div class=" p-t-20"  *ngIf="secondaryInstaller.length">
      <div class="role-name">Secondary Installer</div>
        <ng-container [ngTemplateOutlet]="sitesTblRows"
        [ngTemplateOutletContext]="{ $implicit: secondaryInstaller}">
        </ng-container>
    </div>

    <div *ngIf="!orgs.length && !primarySites.length&&!secondarySites.length && !occupantList.length && !vofmSites.length">
      No roles assigned
    </div>
  </div>
</div>

  <ng-template #sitesTblRows let-sites>
    <div class="buildings m-t-20 p-0-30">
      <div class="building p-l-20 p-r-20 m-b-10" *ngFor="let site of sites">
          <div class="ctnr">
              <div class="name notranslate" title="{{site?.siteName}}">{{site?.siteName}}</div>
              <div class="address">
                  {{site?.locationDetails?.geoAddr}}
              <br />
              {{site?.locationDetails?.geoCity}}
              <br />

              {{site?.locationDetails?.geoState}}
              {{(site?.locationDetails?.geoCountry)?',':''}}
              {{site?.locationDetails?.geoCountry}}
              {{(site?.locationDetails?.geoPostalCode)?',':''}}
              {{site?.locationDetails?.geoPostalCode}}
              </div>
          </div>
      </div>
  </div>
  </ng-template>

  <div class="container" *ngIf="!isSiteExists && !occupantList.length && !vofmSites.length">
      User has no buildings assigned
  </div>

  <div class="col-xs-12 m-b-10 occupant-parent" *ngIf="occupantList.length > 0">
    <ng-container >
      <h2 class="p-5-0 sub-head occupant-zoneAccess" > Occupant Zone Access</h2>
    </ng-container>
    <ng-container *ngIf="occupantList.length > 0">
      <ul class="list-none d-flex flex-direction-row building-info flex-wrap">
        <li class="occ-item d-flex  m-l-10 m-r-10" *ngFor="let site of occupantList;let i =index" >
            <div class="ctnr">
                <div class="name occupant-siteName notranslate" >{{site?.siteName}}</div>
                    <div class="name notranslate">
                      <span *ngFor="let zone of site?.zones; let i=index" class="occupant-zoneName" >{{i != 0 ? ', ':''}}{{zone?.zoneName}}</span>
                    </div>
            </div>
        </li>
    </ul>
    </ng-container>
</div>



