<header id="heatMapHeader" pucClickOutside (outside)="closeAllpopups($event)" [ignore]="['.ignore']">
  <div class="header-ctnr d-flex justify-content-between">
    <div class="d-flex align-items-center">
      <div class="header-text">
        SITE OVERVIEW

      </div>
      <div class="m-l-30">
        <puc-select-dropdown class="fs-header-puc-dropdown" #floorListRef [(ngModel)]="selectedFloor" [placeholderLabel]="" [data]="floors"
          [displayKey]="'name'" multiSelect="true" heatmap="true" [valueKey]="'_id'" (valueChanged)="getFloorId($event)"
          placeholderLabel="All Floors" [disableTranslation]="true" class="notranslate">
        </puc-select-dropdown>
      </div>
      <div class="m-l-25">
        <puc-select-dropdown class="fs-header-puc-dropdown" #ccuListRef [(ngModel)]="selectedCCU" [placeholderLabel]="" [data]="ccusOptions"
          [displayKey]="'name'" multiSelect="true" heatmap="true" [valueKey]="'_id'"
          (valueChanged)="changeCCUselection($event)" placeholderLabel="All CCUs" [disableTranslation]="true" class="notranslate">
        </puc-select-dropdown>

      </div>
      <div class="m-l-25">
        <puc-select-dropdown class="fs-header-puc-dropdown" #zoneListRef [(ngModel)]="selectedZones" placeholderLabel="All zones"
          [data]="filteredOptions" [displayKey]="'name'" multiSelect="true" heatmap="true" [valueKey]="'_id'"
          (valueChanged)="handleOptionSelected($event)" [disableTranslation]="true" class="notranslate"></puc-select-dropdown>
      </div>
    </div>
    <div class="leagend-ctnr" *ngIf="!selectedFilter || (selectedFilter && selectedFilter.filterId=='default')">
      <div class="d-flex">
        <div class="label-text">Cooler</div>
        <div class="d-flex">
          <div class="d-flex scale">
            <div><label>{{deadbandScale[0]}}</label></div>
            <div><label>{{deadbandScale[1]}}</label></div>
            <div><label>{{deadbandScale[2]}}</label></div>
            <div><label>{{deadbandScale[3]}}</label></div>
            <div><label>{{deadbandScale[4]}}</label></div>
            <div><label>{{deadbandScale[5]}}</label></div>
            <div><label>{{deadbandScale[6]}}</label></div>
          </div>
        </div>
        <div class="label-text">Hotter</div>
      </div>
    </div>
    <div *ngIf="!hideQRCode && showQRCode">
      <button class="qrCode-button" (click)="openQRCodeList()" mat-button id="qr-Code-Button">
        <img src="assets/images/qrCode.svg" class="qr-image" matTooltip="QR Code" matTooltipPosition="above" [matTooltipClass]="['qrCode-tooltip', 'common-style-tooltip']"  alt="qr-code-icon"  />
     </button>
    </div>
    <div class="refresh-button-container">
      <button  [matTooltip]="autoRefreshTooltipStatus(autoRefreshSelection)" matTooltipPosition="above"  [matTooltipClass]="['auto-refresh-tooltip', 'common-style-tooltip']" class="refresh-button" mat-button [matMenuTriggerFor]="menu" #refresh="matMenuTrigger"
        [class.ref-active]="autoRefreshSelection != 6 && autoRefreshSelection != 1" [class.opened]="refresh.menuOpen">
        <i class="fas fa-sync-alt"></i>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="setAutoRefresh(1)">Refresh Now</button>
        <button mat-menu-item (click)="setAutoRefresh(60)" [ngClass]="{'selected': autoRefreshSelection == 60}">Every 1
          min</button>
        <button mat-menu-item (click)="setAutoRefresh(300)" [ngClass]="{'selected': autoRefreshSelection == 300}">Every
          5 mins</button>
        <button mat-menu-item (click)="setAutoRefresh(900)" [ngClass]="{'selected': autoRefreshSelection == 900}">Every
          15 mins</button>
        <button mat-menu-item (click)="setAutoRefresh(3600)"
          [ngClass]="{'selected': autoRefreshSelection == 3600}">Every 60 mins</button>
        <button mat-menu-item (click)="setAutoRefresh(6)" [ngClass]="{'selected': autoRefreshSelection == 6}">Turn
          Off Auto Refresh</button>
      </mat-menu>
    </div>
    <div *ngIf="checkCertificationAccess('notes')" class="pinned-notes">
      <button class="pin-button" mat-button 
        (click)="openPinnedNotes()" id="pinned-notes" [matTooltip]="notesList?.length + ' Pinned Notes'"  matTooltipPosition="above" [matTooltipClass]="['pinned-notes-tooltip','common-style-tooltip']">
        <i class="fas fa-thumbtack pinIcon" (mouseenter)="fetchNotesLength()"></i>
      </button>
    </div>
    <div>
      <puc-heatmap-filter-common-pool [siteId]="siteId" [selectedFilter]="selectedFilter" [selectedDefaultFilterId]="defaultFilter" [fromInternal]="true" (applyFilter)="applyFilter($event)" (selectDefaultFilter)="selectDefaultFilter($event)" [siteName]="siteName" (filterLiked)="getFilterById($event)"></puc-heatmap-filter-common-pool>
    </div>
  </div>

  <div class="ccu-list-ctnr" [ngClass]="{'display_none': hideCCUHeader, 'p-b-10': hideCcuHeaderIcon && expandCcu}">
    <div class="reorder-ccu" (click)="sortCCU()">
      <img alt="sort-image" src="assets/images/reorder.svg" class="sort-icon" matTooltip="Reorder CCU" matTooltipPosition="above" [matTooltipClass]="['reorder-ccu-tooltip', 'common-style-tooltip']">
    </div>
    <div class="row align-items-center ccu-ctnr" [ngClass]="{'height_pos': hideCCUHeader}">
      <div class="flex-grow" [ngClass]="{'p-b-5':!expandCcu}">
        <div id="ccu-list" class="ccu-scroll-list notranslate" [ngClass]="{'ccu-expand-list' : expandCcu}">
          <button id="{{ccu._id}}" class="ccu-list"  [class.ccuhighlight]="highlightCCU==ccu._id" id="{{ccu._id}}"
            [ngStyle]="{'color': ccu.color}"
            [ngClass]="{'ccu-label-without-hover': highlightCCU != ccu._id && highlightCCU,'ccu-label-with-hover': highlightCCU == ccu._id }"
            (mouseenter)="onCcuHover(ccu._id, $event)"
            (mouseleave)="onCcuLeave($event)"
            (click)="ccuSettings(ccu._id, $event)"
            *ngFor="let ccu of ccus; let i = index;trackBy: ccuId">{{ ccu.name }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!hideCCUHeader">
    <div *ngIf="hideCcuHeaderIcon" class="bg-white d-flex">
      <span *ngIf="expandCcu" class="expand-icon fas fa-angle-double-up header-drag-icon"
        (click)="ccuListHeader(expandCcu)"></span>
      <span *ngIf="!expandCcu" class="expand-icon  fas fa-angle-double-down header-drag-icon"
        (click)="ccuListHeader(expandCcu)"></span>
    </div>

    <div class="d-flex filter-legend">
        <puc-color-swatch class="w-100" [siteId]="siteId" [selectedFilter]="selectedFilter" [defaultFilter]="defaultFilter" (removeSelectedFilter)="clearFilteredData($event)"
        id="colorSwatch"></puc-color-swatch>
      <div class="compass-container" *ngIf="showOrientationCompass">
        <img alt="compass-image" class="compass-img" [ngStyle]="{'transform': 'rotate(' + orientationangle + 'deg  )'}"
          src="assets/images/compass.svg" />
      </div>
    </div>
  </ng-container>
</header>