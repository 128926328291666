<div class="account-layout">
    <span class="layout-header">
        User Management
    </span>
    <span class="search">
        <puc-common-search [inputPlaceholder]="'Search for Organization/Site'" (searchText)="onSiteSearch($event)" (clearSearch)="clearFilterSearch()"></puc-common-search>
    </span>
    
    
    <div class="container">

        <div class="content">
            <div class="row h-100">
                <div class="user-management-content" *ngIf="tempOrgList?.length > 0">
                    <div *ngFor="let org of tempOrgList; let i = index; trackBy: trackByOrgId">
                        <puc-accordion (click) ="onaccordionClick(i)" [isOpen]="true" [blockTitle]="org.orgName" [isHeatmap]="true" [disableTranslation]="true">
                            <div class="m-t-20" *ngFor="let site of org.sites;let index=index;trackBy: trackBySiteId">
                                <i *ngIf="site.isPrimary" class="fm-user fas fa-flag p-r-10 m-t-5" aria-hidden="true"></i>
                                <span
                                    class="bname notranslate">{{site.siteName}}</span>
                                <div>
                                    <ng-container *ngIf="site?.showAddUser">
                                    <fs-add-users [isPrimary]="site.isPrimary" [siteId]="site.siteId"
                                        [orgManager]="site.orgManager" [role]="site.role" ></fs-add-users>
                                    </ng-container>
                                    <fs-user-management-table *ngIf="site.users" [userId]="userId" [showAddUser]="site?.showAddUser" [siteId]="site.siteId" [data]="site.users">
                                    </fs-user-management-table>
                                </div>
    
                            </div>
                        </puc-accordion>
                        <hr class="horizotal-line" [class.hide-horizontal]="isOrgExpanded(i)">
                    </div>
                </div>
                <div *ngIf="tempOrgList?.length === 0"  class="text-center m-100-a">No records found.</div>
            </div>
        </div>
    </div>
</div>